@media (max-width: 991px) {
	.popup {
		&.popup.polis .inner-popup { width: 90%; 
			.polis-popup .list .item button { margin-top: 10px; }
			form {
				.form-group { margin-bottom: 20px; }
				.row { flex-direction: row; flex-wrap: wrap;
					.column.column-30 { padding-right: 10px; }
					.column.column-33, .column.column-50 { flex: 0 0 100%; max-width: 100%; margin-bottom: 20px; 
						&:last-child { margin-bottom: 0; }
					}
				}
				textarea { margin-bottom: 0; }
				input[type="text"],input[type="email"] { padding: 5px 10px; }
				select { padding-left: 10px; }
			}
		}
		.inner-popup { padding: 4rem 6rem; width: 90%; }
		.title-popup h4 { font-size: 1.7rem; }
	}
}
