@media (max-width: 1279px){
	.promotion {
		&.page .promotion-list { margin: -40px -7px 0;
			.item { margin: 0 7px 32px; width: calc(100% / 3 - 14px);
				figure { margin-bottom: 14px; }
				h4 { font-size: 15px; line-height: 24px; }
			}
			&.blog {
				.item { margin-bottom: 32px;
					figure { margin-bottom: 14px;
						.tag { font-size: 11px; }
					}
					h4 { font-size: 15px; line-height: 24px; }
				}
			}
		}
		&.other { margin-bottom: 30px; }
	}
	.blog-detail {
		.wrapper { padding: 0 40px; }
		.tags { margin-bottom: 40px;
			.date { font-size: 15px; margin-top: 5px; }
		}
		.share { left: -18px; 
			li { font-size: 12px; line-height: 25px;
				img { height: auto; width: 25px; }
			}
		}
		.title { font-size: 34px; line-height: 44px; margin-bottom: 44px; padding-bottom: 70px; }
	}
	.promo-detail { padding-top: 43px;
		.promo-ticket .text { padding: 30px 46px;
			h4 { font-size: 17px; line-height: 30px; }
			.button { font-size: 14px; margin-bottom: 0; }
		}
		.promo-terms { margin-bottom: 100px; padding: 40px 60px; }
	}
}
@media (max-width: 991px) {
	.product-page .product .item {
		figure img { height: 188px; }
		.text {
			img { margin-bottom: 10px; }
			h4{ font-size: 15px; line-height: 24px; margin-bottom: 20px; }
			p { display: none; }
		}
	}
	.promotion {
		&.page .promotion-list { margin: -40px -8px 0;
			.item { margin: 0 8px 32px; width: calc(100% / 2 - 16px); }
		}
		&.other { min-height: auto;
			.promotion-list.blog .item:last-child { display: none; }
		}
	}
	.blog-detail {
		.tags { margin-bottom: 30px; }
		.title { font-size: 23px; line-height: 34px; padding-bottom: 20px; padding-right: 100px; margin-bottom: 30px; 
			&:before {  width: 40px; }
		}
	}
	.promo-detail {
		.promo-ticket {
			.img { width: 45%; }
			.text { padding: 24px 16px 24px 36px; width: 55%; 
				h4{ font-size: 15px; line-height: 24px; margin-bottom: 20px; }
				table tr td { min-width: 120px; }
			}
		}
		.promo-terms { padding: 42px; }
	}
}