@media (max-width: 767px) {
  .section-account .box-aside {
    aside.trans {
      margin-bottom: 16px;
    }
    article .content-profile form.form2 .form-group {
      label {
        font-weight: bold;
        width: 50%;
      }
      > label {
        width: 100%;
      }
    }
  }
  .section-account .box-aside .form-group.row {
    &.no-margin {
      .column label {
        width: 100% !important;
      }
      .row {
        margin: 0 -5px;
        width: calc(100% + 10px);
      }
    }
    .row {
      margin: 0;
      width: calc(100%);
    }
  }
  .box-aside {
    aside {
      .step-transaction {
        &.tri-step {
          .list {
            min-height: 60px;
            padding: 2rem 1.5rem 2rem 5.5rem;
            width: 33.33%;
            .text-step {
              display: none;
              h6 {
                display: block;
              }
              h5 {
                display: none;
              }
            }
          }
        }
        .list {
          min-height: 60px;
          padding: 0;
          width: 20%;
          &.active .num-step:before,
          &.done .num-step:before {
            @include transform(scale(3.5));
          }
          .num-step {
            height: 2.4rem;
            left: 50%;
            font-size: 1rem;
            line-height: 2.4rem;
            width: 2.4rem;
            @include transform(translate(-50%, -50%));
          }
          &.active {
            padding-right: 0;
          }
          .text-step {
            display: none;
          }
        }
      }
    }
    .box-data-list {
      .row {
        .column.column-50 {
          margin-bottom: 0;
        }
      }
    }
    article .content-profile {
      padding: 3rem 2rem;
      form {
        .column,
        .column-33,
        .column-35,
        .column-45,
        .column-50,
        .column-55,
        .column-65 {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0 5px;
          width: 100%;
        }
        .column-45 .side-label-text,
        .column-65 .side-label-text {
          flex-wrap: wrap;
          label {
            margin: 30px 0 20px !important;
            width: 100%;
          }
        }
        .column-50 {
          max-width: 100%;
          width: 100%;
          .row {
            flex-direction: row;
          }
        }
        .column-10 {
          margin: 25px auto 0px;
          max-width: 100%;
          width: 100%;
        }
        .column.column-33,
        .column.column-50 {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .row .row {
        margin: 0 -10px;
        width: calc(100% + 20px);
        .column.column-20 {
          padding-left: 0;
          padding-right: 0;
          flex: 0 0 20%;
          max-width: 20%;
        }
        .column.column-33 {
          flex: 0 0 calc(33% - 20px);
          margin: 0 10px;
          max-width: calc(33% - 20px);
        }
        .column.column-30 {
          padding-left: 0;
          padding-right: 10px;
          flex: 0 0 30%;
          max-width: 30%;
        }
        .column.column-40 {
          padding-left: 0;
          padding-right: 0;
          flex: 0 0 40%;
          max-width: 40%;
        }
      }
      &.notable {
        form .form-group.row {
          margin-bottom: 30px;
          &.no-margin {
            margin-bottom: 0;
          }
          .column.column-33,
          .column.column-50 {
            margin-bottom: 30px;
            padding: 0;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .column.column-100 {
            padding: 0;
          }
        }
      }
      form {
        > h4 {
          font-size: 1.6rem;
          line-height: 3rem;
        }
        p {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
      .center-align .button,
      .center-align button {
        width: 100%;
      }
    }
    .need-help {
      padding: 1.4rem 1.9rem;
      h5 {
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }
  }
  .need-help {
    padding: 1.4rem 1.9rem;
    h5 {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
  .summary {
    padding: 1.4rem 1.9rem 0;
    h5 {
      font-size: 1.3rem;
      line-height: 2rem;
    }
    .row {
      flex-direction: row;
    }
  }
  .need-help {
    padding: 1.4rem 1.9rem;
    h5 {
      font-size: 1.3rem;
      line-height: 2rem;
    }
  }
  .need-help-mobile {
    margin: 2rem -1.9rem 0;
    padding: 1.2rem 1.9rem;
  }
  .total-mobile {
    display: block;
    padding: 0 1.9rem;
    .row {
      flex-direction: row;
      .column {
        margin: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        h5 {
          font-size: 1.3rem;
          line-height: 2rem;
        }
      }
    }
  }
  .box-checkbox-box .checkbox-box {
    margin-bottom: 1.2rem;
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
    .inner-checkbox {
      height: 12.6rem;
      width: 12.6rem;
    }
    span {
      font-size: 1.3rem;
      line-height: 2rem;
    }
    input + .inner-checkbox img {
      // height: 2.9rem;
      width: auto;
    }
    &.check-white {
      margin-bottom: 1.2rem;
      margin-right: 4%;
      width: 48%;
      &:nth-child(2n),
      &:last-child {
        margin-right: 0;
      }
      .inner-checkbox {
        height: 12.6rem;
        width: 100%;
      }
    }
    &.check-package {
      margin-right: 0;
      margin-bottom: 1.2rem;
      width: 100%;
      .inner-checkbox {
        width: 100%;
      }
    }
  }
  .box-soft-orange {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 2.2rem;
  }
  .promo-box {
    flex-wrap: wrap;
    padding: 1.6rem 1.3rem;
    label {
      font-size: 1.3rem;
      line-height: 2rem;
      margin-bottom: 1.6rem;
    }
  }
  .box-text-grey {
    input[type="text"] {
      padding: 0 3rem 0 2rem;
      width: 65%;
    }
    button {
      width: auto;
    }
  }
  .selected-box a {
    margin-bottom: 1rem;
    width: 100%;
  }
  .box-soft-orange {
    &.goal {
      display: block;
      .link-arrow {
        display: inline-block;
        margin-top: 3.7rem;
      }
    }
    table th,
    td {
      display: block;
      padding: 0;
      width: 100%;
    }
    td {
      font-size: 1.4rem;
    }
  }
  .box-asuransi-list .list-asuransi {
    .title-asuransi {
      flex-wrap: wrap;
      .tl-left {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        padding: 1.8rem;
        width: 100%;
        h4 {
          font-size: 1.6rem;
          line-height: 3rem;
        }
      }
      table tr {
        th {
          font-size: 1.3rem;
        }
        td {
          font-size: 1.4rem;
          padding: 0 1.2rem 0 0;
        }
      }
      .tl-right {
        flex-wrap: wrap;
        padding: 1.8rem 1.8rem 2.1rem;
        width: 100%;
        table {
          margin-right: 0;
          width: 100%;
        }
        .button {
          margin-top: 1.5rem;
          width: 100%;
        }
      }
    }
    .content-asuransi {
      padding: 1.8rem;
      table {
        display: block;
        tr td,
        tr th {
          display: block;
          padding: 0;
          width: 100%;
          &:last-child {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
  .box-soft-orange.goal {
    table {
      margin-right: 0;
      width: 100%;
    }
    .link-arrow {
      width: auto;
    }
  }
  .box-leisure .list-leisure {
    flex-wrap: wrap;
    h5,
    p {
      font-size: 1.4rem;
      line-height: 2.6rem;
    }
    .left-lei {
      width: 100%;
    }
    .right-lei {
      margin-top: 30px;
      width: 100%;
    }
  }
  .box-total-asuransi {
    .top-total-asuransi {
      flex-wrap: wrap;
      .left-total {
        border: none;
        border-bottom: 1px dashed #e5e5e5;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 2.5rem 1.6rem;
      }
      .right-total {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 2.5rem 1.6rem;
      }
    }
    .bottom-total-asuransi {
      padding: 1.5rem;
      label,
      h4 {
        font-size: 1.7rem;
      }
    }
  }
  .tab-rounded {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .btn-tab-rounded {
      margin-bottom: 2.9rem;
      a {
        margin-right: 1rem;
        padding: 1.5rem;
        text-align: center;
        width: calc(50% - 1rem);
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  label.checkbox-form span {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 2.4rem;
  }
  .box-rounded-grey {
    > p {
      margin: 0 -10px 30px;
    }
    .top-line-rounded {
      flex-wrap: wrap;
      padding: 0;
      h4 {
        font-size: 1.6rem;
        line-height: 3rem;
      }
      h6 {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
      .left-box {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 2rem 2.6rem;
        text-align: center;
      }
      .right-box {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 2rem 2.6rem;
        text-align: center;
      }
    }
    &.box-accordion {
      .box-rounded-dashed-grey {
        margin: 0;
      }
    }
  }
  .box-rounded-dashed-grey > .checkbox-form {
    width: 100% !important;
  }
  .box-dash-rounded {
    margin-bottom: 20px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .form-group.row .column.column-50 {
      padding: 0 15px !important;
    }
  }
  .box-paket {
    margin-bottom: 2.2rem;
    padding: 3.2rem 1.8rem 0;
    .list-paket {
      flex-wrap: wrap;
      margin: 0;
      padding: 0 0 3rem;
      position: relative;
      .left-paket > div:first-child {
        margin-bottom: 20px;
      }
    }
    .right-paket {
      position: absolute;
      top: 46px;
    }
    .subtotal-paket {
      margin: 0;
      padding: 2.2rem 0;
    }
    .total-paket {
      margin: 0 -1.8rem;
      padding: 1.7rem 2.6rem;
      width: calc(100% + 3.6rem);
      label,
      h5 {
        font-size: 1.6rem;
        line-height: 3rem;
      }
    }
  }
  .title-dash {
    margin: 0 0 1rem 0;
    h4 {
      display: block;
    }
    button {
      float: none;
      width: 100%;
    }
  }
  .box-rounded-grey h4,
  .box-rounded-grey h5 {
    margin: 0 0 2rem !important;
  }
  .pdl0 .column {
    padding-left: 0 !important;
    &:last-child {
      padding-right: 0 !important;
    }
  }
  .box-rounded-dashed-grey {
    padding: 2.2rem 1.5rem;
  }

  .wrap-data-polis {
    .box-rounded-grey {
      // padding: 3rem 1rem 0;
      &.box-accordion {
        margin: 0 0 20px;
        .button-accordion {
          padding: 16px 38px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          &:before {
            right: 3rem;
            // top: 1.5rem;
            top: auto;
          }
          .delete-child {
            top: 16px;
            right: 64px;
          }
        }
      }
      .box-rounded-dashed-grey {
        padding: 2rem 0 1rem;
        border: unset;
        .checkbox-form {
          padding-left: 4rem;
          input[type="checkbox"] {
            top: 0.3rem;
            left: 0.6rem;
          }
        }
      }
    }
  }
  .add-item {
    margin: 0 0 3rem;
  }
  .box-white {
    padding: 30px 18px;
    .title-ty {
      h5 {
        font-size: 16px;
      }
    }
    .wrap-time {
      h5 {
        font-size: 14px;
      }
      time {
        margin: 10px 0;
      }
    }
    .desc-bank {
      align-items: flex-end;
      .desc-bank--text {
        margin-left: 0px;
      }
      figure {
        flex-direction: column;
        align-items: flex-start;
        .text {
          margin-left: 0;
          margin-top: 10px;
          &.with-btn {
            font-size: 20px;
            line-height: 32px;
            gap: 10px;
            margin-top: 0;
          }
        }
      }
      .total {
        font-size: 20px;
      }
    }
    .button {
      height: 45px;
      line-height: 45px;
      margin-top: 20px;
    }
  }
  .triger-show-floating {
    padding: 1rem 1.9rem;
    span {
      font-size: 1.2rem;
    }
  }
}
