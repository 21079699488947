@media (max-width: 1480px) {
	.section-login.login {
		.center-login {
			margin-top: 0;
		}
		.foooter.register {
	    margin-top: 30px;
		}
	}
}
@media (max-width: 1366px) {
	.section-login.type2 {
		.logo {
	    left: 20px;
	    top: 20px;
			> img {
				height: 45px;
			}
		}
	}
}