@media (max-width: 1279px) {
  body {
    padding-top: 80px;
  }
  header {
    height: auto;
    .wrapper {
      width: 896px;
    }
    .logo {
      padding: 12.5px 0;
      img {
        height: 48px;
        width: auto;
      }
    }
    nav {
      margin-right: 60px;
      .menu {
        > li {
          display: none;
          &.nav-profile,
          &.nav-search,
          &.nav-cart {
            display: block;
          }
          &.nav-profile {
            > a {
              padding: 26px 0 19px;
              span {
                line-height: 27px;
              }
            }
          }
          > a {
            padding: 26px 0 24px;
          }
          &.nav-search img {
            padding: 30px 0 30px;
          }
          &.nav-cart .button {
            height: 40px;
            line-height: 40px;
            margin: 20px 0;
            > img {
              top: 8px;
            }
          }
        }
      }
    }
    .burger-menu {
      cursor: pointer;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      @include transform(translateY(-50%));
      > img {
        display: block;
      }
    }
  }
  .header-login {
    padding: 1.2rem 4rem 1.2rem 4rem;
    .logo img {
      height: 48px;
    }
    .right-header {
      p {
        font-size: 15px;
        margin-top: 0;
      }
      a {
        font-size: 15px;
        margin-top: 0;
        position: relative;
        top: 2px;
        &:after {
          top: 3px;
        }
      }
    }
  }
  .banner {
    .item {
      figure img {
        height: 600px;
        max-height: 600px;
      }
      .item-text {
        padding-left: 0;
        width: 50%;
        > img {
          height: 32px;
          margin-bottom: 10px;
          width: auto;
        }
        h1 {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 10px;
        }
        p {
          font-size: 19px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .button {
          font-size: 15px;
        }
      }
    }
  }
  .banner-small .item {
    figure img {
      height: 300px;
    }
    .wrapper {
      top: 50%;
      @include transform(translateY(-50%));
    }
    .item-text h1 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  .bottom-background {
    margin-top: -70px;
  }
  .sumary-text {
    margin: -140px auto 34px;
    .wrapper {
      padding: 60px 60px 40px 100px;
    }
    article {
      .title {
        font-size: 24px;
        line-height: 34px;
        padding-bottom: 0;
        &:before {
          display: none;
        }
      }
      h4 {
        font-size: 24px;
        line-height: 34px;
      }
      h5 {
        font-size: 19px;
        line-height: 30px;
      }
      p {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .std-content {
    h3 {
      font-size: 24px;
      line-height: 40px;
    }
    h4 {
      font-size: 19px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 30px;
    }
  }
  .title {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 60px;
    padding-bottom: 32px;
  }
  .title-section {
    font-size: 19px;
    margin-bottom: 30px;
  }
  .wrapper {
    width: 896px;
  }
  footer {
    .foot-left {
      .ojk {
        font-size: 13px;
        margin-bottom: 0;
      }
      .social-media {
        img {
          max-height: 25px;
          width: auto;
        }
        a {
          margin-right: 10px;
        }
      }
      .foot-sponsor {
        margin-bottom: 20px;
        li {
          margin-left: 10px;
          margin-bottom: 0;
          img {
            max-height: 59px;
            width: auto;
          }
        }
      }
    }
    .foot-right {
      width: 215px;
    }
    .foot-bottom {
      margin-top: 0;
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  header {
    .wrapper {
      width: 90%;
    }
    nav {
      margin-right: 55px;
      .menu > li {
        &.nav-profile .has-login {
          .profile {
            padding: 27px 33px;
            img {
              height: 48px;
              margin-right: 16px;
              width: 48px;
            }
            div h5 {
              font-size: 16px;
              padding-top: 0;
            }
          }
        }
        &.nav-search {
          margin-left: 17px;
          .nav-search-box {
            width: 600px;
          }
        }
        &.nav-cart {
          margin-left: 16px;
          .button {
            padding: 0 11px;
            text-indent: -9999px;
            > img {
              margin-right: 0;
            }
          }
        }
      }
    }
    .menu-mobile {
      bottom: unset;
      height: 663px;
      margin-right: 0;
      margin-top: -100%;
      padding-bottom: 80px;
      width: 100%;
      z-index: 999;
      &.active {
        margin-top: 0;
      }
      > ul {
        height: 100%;
        max-height: 100%;
      }
    }
  }
  .header-login {
    left: 0;
    width: 100%;
  }
  a.link-with-icon:after {
    display: inline-block;
    @include transform(scale(0.8));
  }
  .banner .item .item-text {
    width: 60%;
    h1 {
      font-size: 23px;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
  .banner-small .item {
    figure img {
      height: 240px;
    }
    .item-text h1 {
      font-size: 23px;
      line-height: 40px;
    }
  }
  .bottom-background {
    margin-top: 0;
  }
  .sumary-text {
    .wrapper {
      padding: 60px 42px 30px 78px;
    }
    article {
      &:before {
        left: -42px;
      }
      .title {
        font-size: 23px;
        line-height: 38px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .title {
    font-size: 23px;
    line-height: 34px;
    padding-bottom: 24px;
    margin-bottom: 30px;
    &:before {
      width: 40px;
    }
  }
  .title-section {
    font-size: 17px;
  }
  .wrapper {
    width: 90%;
  }
  .wrapper-small {
    width: 100%;
  }
  .std-content {
    .row .box-half:first-child {
      padding-right: 0;
    }
    h3 {
      font-size: 20px;
      line-height: 34px;
    }
    h4 {
      font-size: 17px;
      line-height: 30px;
    }
    h5 {
      font-size: 16px;
      line-height: 30px;
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
    ul > li,
    ol > li {
      font-size: 14px;
      line-height: 30px;
    }
  }
  footer {
    padding: 40px 0 37px;
    .foot-left {
      padding-top: 5px;
      width: calc(100% - 173px);
      flex-direction: column;
      align-items: flex-end;
      .foot-sponsor,
      .social-media {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100%;
        width: auto;
      }
      .foot-sponsor {
        li {
          img {
            max-height: 44px;
          }
        }
      }
    }
    .foot-right {
      width: 173px;
    }
    .foot-bottom {
      text-align: center;
      width: 100%;
      .sitemap {
        text-align: center;
        li {
          display: inline-block;
          float: none;
        }
      }
      .copyright {
        text-align: center;
      }
    }
  }
}
