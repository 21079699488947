a[href^=tel] { color: inherit; }
.contact-us { margin-top: -50px; position: relative; z-index: 9;
	.wrapper { border-radius: 20px; box-shadow: 0 10px 40px rgba(0,0,0,.1); display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; }
	.maps { background: #f8f8f8; border-radius: 20px 0 0 0;  width: 560px; 
		iframe { width: 100%; }
		.address { padding: 57px 52px; 
			h4 { font-size: 24px; line-height: 30px; margin-bottom: 8px; }
			h5 { font-size: 23px; line-height: 30px; margin-bottom: 0; }
			p { font-size: 16px; line-height: 30px; }
			i, tel { color: #333; }
			a[href^="tel"] { color: inherit; text-decoration: none; }
		}
	}
	.contact-form { background: $color-initial; padding: 59px 103px 59px 58px; width: calc(100% - 560px); 
		.thank-you { display: none;
			h4 { font-size: 24px; font-weight: bold; }
			img { display: block; margin: 42px auto 57px; }
			p { font-size: 16px; line-height: 30px; margin-bottom: 20px; }
			button { height: 40px; line-height: 40px; margin-bottom: 0; width: 190px; }
		}
	}
}
.checkbox-form.light { font-weight: normal; 
	span { font-weight: normal; }
  &.underline {
    font-size: 13px; 
    a {
      color: #494949;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
form {
	.form-group { margin-bottom: 32px; 
		label { font-size: 13px; font-weight: bold; margin-bottom: 0; }
		input[type="text"],input[type="email"],input[type="password"], input[type="month"], select{
			border: none; border-bottom: 1px solid #E5E5E5; font-size: 15px; padding: 10px 0; 
			border-radius: 0;font-weight: 500;
			margin-bottom: 0;

			@include andplaceholder{
				color: #A1A1A1;
			}

			&:focus{
				color: #1B1464;
				border-bottom-color:#443C9B;
			}

			&.text-rounded{
				border: .1rem solid #E5E5E5;
				border-radius: .5rem;
				height: 5rem;
				padding: 0 2rem;
				font-weight: 400;
			}
		}
		select{
			-webkit-appearance:none;
			background: url('../images/material/icon-select.svg')no-repeat right center;
			background-position: right 10px center;
			padding: .7rem;
			padding-right: 2rem;
			padding-left: 0;
			width: 100%;
			option { font-size: 16px; padding: 2rem; width: 100%; }
			&.v-border{
				border: .1rem solid #E5E5E5;
				border-radius: .5rem;
				height: 5rem;
				padding: 0 4rem 0 2rem;
				font-weight: 400;
				background-image: url('../images/material/arrow-select-2.svg');
			}
		}
		textarea { 
			border: none; border-bottom: 1px solid #E5E5E5; font-size: 15px; height: 200px; padding: 10px 0; resize: none;
			border-radius: 0;

			&.text-rounded{
				border: .1rem solid #E5E5E5;
				border-radius: .5rem;
				padding: 1.5rem 2rem;
				font-weight: 400;
				height: 9rem;
			}
		}
		input[type="button"], input[type="submit"], .button { height: 40px; line-height: 40px; margin-bottom: 0; min-width: 190px; }
		
		&.no-margin{margin-bottom: 0;}
		&.light-margin{margin-bottom: 1rem;}
		.select2, .select-clone {
			width: 100% !important;
			
			.select2-selection--single .select2-selection__rendered {
				line-height: 3.8rem;
				font-size: 15px;
				font-weight: 500;
			}
		}
		.select2-container .select2-selection--single {
			height: 3.8rem;
			border: .1rem solid #ccc;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow {
			background: url('../images/material/icon-select.svg') no-repeat center;
			top: 0;
			bottom: 0;
			height: auto;
			right: 6px;
			b {
				display: none;
			}
		}
	}
	.row{
		&.col-right, .col-right{
			text-align: right;
		}
	}
	.btn-group {
		.g-recaptcha { float: left; transform-origin: 0 0; @include transform(scale(.8)); }
		.btn { float: right; 
			input[type="button"] { height: 40px; line-height: 40px; margin-bottom: 0; width: 190px; }
		}
	}
	.text-number{
		position: relative;
		display: inline-block;
		.min,.plus{
			display: block;
			width: 5rem;
			height: 5rem;
			line-height: 5rem;
			text-align: center;
			position: absolute;
			top: 0;
			bottom: 0;
			font-weight: 700;
			font-size: 2rem;
			color: #39337E;
			cursor: pointer;

			&:before,&:after{
				content:"";
				display: block;
				width: 1.2rem;
				height: .2rem;
				background: #39337E;
				margin: 0 auto;
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
			}
		}
		.min{
			left: 0;
			border-right: .1rem solid #E5E5E5;
		}
		.plus{
			right: 0;
			border-left: .1rem solid #E5E5E5;

			&:after{
				transform: rotate(90deg);
			}
		}
		input[type=text]{
			width: 17rem;
			padding: 0 6rem;
			border-radius: .5rem;
			border: .1rem solid #E5E5E5;
			height: 5rem;
			text-align: center;

			&:active, &:focus{
				border: .1rem solid #E5E5E5;
			}
		}
	}

	.text-label{
		display: flex;
		border: .1rem solid #E5E5E5;
		border-radius: .5rem;
		height: 5rem;
		width: 14rem;

		input[type=text]{
			border: none;
			padding: 0 0 0 2rem;
			height: 5rem;
		}
		span{
			display: block;
			line-height: 5rem;
			padding: 0 2rem;
			font-size: 1.5rem;
		}
	}
	.side-label-text{
		display: flex;
		margin-bottom: 1rem;

		label{
			font-weight: 400;
			margin: 0 2rem 0 0;
			white-space: nowrap;
			line-height: 5rem;
		}
	}

	&.column-no-margin{
		.column{margin-bottom: 0;}
	}
	&.no-margin{
		margin-bottom: 0;
	}

	&.form2{
		.form-group{
			label{
				font-size: 1.6rem;
				margin-bottom: 2rem;
			}
		}
		.side-label-text{
			label{
				margin-bottom: 0;
			}
		}
	}
  &.form-sport{
		.form-group{
			label{
				font-size: 1.6rem;
			}
		}
		.side-label-text{
			label{
				margin-bottom: 0;
			}
		}
    .box-checkbox-box {
      margin-top: 16px; 
    }
	}
  input.icon-date {
    background: url('../images/material//ico-calendar.svg') no-repeat right 5px;
    padding-right: 35px !important; 
  }
}

