@media (max-width: 1279px){
	.contact-us {
		.maps { width: 45%; 
			.address { padding: 49px 60px; 
				h4,h5 { font-size: 19px; line-height: 30px; margin-bottom: 0; }
				p { font-size: 16px; line-height: 30px; margin: 15px 0 0; }
			}
		}
		.contact-form { padding: 59px 60px; width: 55%; 
			textarea { margin-bottom: 0 !important; }
		}
	}
	form .btn-group { display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: wrap;
		.g-recaptcha { width: calc(100% - 190px); @include transform(scale(.53)); }
		.btn { width: 190px; }
	}
}

@media (max-width: 991px) {
	.contact-us {
		.maps .address { padding: 30px; 
			h4,h5 { font-size: 17px; line-height: 30px; margin-bottom: 0; }
			p { font-size: 14px; line-height: 30px; margin: 15px 0 0; }
		}
		.contact-form { padding: 60px 42px 40px 37px; }
	}
	form.form2 .form-group label { margin-bottom: 2.9rem; }
	form {
		.form-group {
			input[type="text"], input[type="email"], input[type="password"], select { font-size: 14px; }
			select{
				background-position: right center;
				background-size: 8px;
				padding: 0 8px 0 0;
			}
		}
		.btn-group {
			.g-recaptcha { float: none; width: 100%; @include transform(scale(.7)); }
			.btn { float: none; }
		}
	}
}