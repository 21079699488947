/* header
----------------------------------------------------------------------------------------------*/
header {
  background: $color-initial;
  height: 100px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  .wrapper {
    width: 1280px;
    @include afterclear;
  }
  .logo {
    float: left;
    padding: 6px 0;
    h1 {
      margin: 0;
      line-height: 1;
    }
  }
  nav {
    float: right;
    .menu {
      margin-bottom: 0;
      @include afterclear;
      > li {
        float: left;
        margin-bottom: 0;
        margin-left: 31px;
        > a {
          color: $color-quaternary;
          display: block;
          padding: 40px 0 34px;
          > img {
            float: left;
          }
          &.active {
            font-weight: bold;
          }
        }
        &.nav-language {
          margin-left: 100px;
          position: relative;
          > span {
            color: $color-quaternary;
            cursor: pointer;
            display: block;
            padding: 40px 0 34px;
            > img {
              margin-left: 5px;
            }
          }
          /*&:hover {
						.nav-language-box { display: block; }
					}*/
          .nav-language-box {
            display: none;
            left: 50%;
            margin: 0;
            min-width: auto;
            padding-top: 19px;
            position: absolute;
            top: 100%;
            width: auto;
            z-index: 9999;
            @include transform(translateX(-50%));
            &:before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid $color-initial;
              border-radius: 2px;
              content: "";
              height: 0;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              top: 13px;
              width: 0;
            }
            > div {
              background: $color-initial;
              border-radius: 10px;
              padding: 10px;
              width: max-content;
              a {
                color: $color-quaternary;
                display: inline-block;
                padding: 0 10px;
                text-align: center;
                &:first-child {
                  border-right: 1px solid #e2e2e2;
                }
                &.active {
                  color: $color-primary;
                  font-weight: bold;
                }
              }
            }
          }
        }
        &.nav-profile {
          margin-left: 24px;
          position: relative;
          > a {
            padding: 40px 0 27px;
            > img {
              margin-right: 7px;
              position: relative;
              top: -3px;
            }
            &.login {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 125px;
                display: inline-block;
              }
            }
          }
          .has-login {
            display: none;
            left: 50%;
            margin: 0;
            padding-top: 19px;
            position: absolute;
            top: 100%;
            width: 290px;
            z-index: 9999;
            @include transform(translateX(-50%));
            &:before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid $color-secondary;
              border-radius: 2px;
              content: "";
              height: 0;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              top: 13px;
              width: 0;
            }
            .profile {
              background: $color-secondary;
              border-radius: 10px 10px 0 0;
              padding: 20px 20px 25px;
              > img {
                float: left;
                height: 60px;
                object-fit: cover;
                object-position: center;
                margin-right: 19px;
                width: 60px;
              }
              div {
                h5 {
                  color: $color-initial;
                  font-size: 18px;
                  font-weight: 500;
                  margin-bottom: 0;
                  padding-top: 4px;
                }
                span {
                  color: $color-initial;
                  display: block;
                  font-size: 13px;
                  opacity: 0.6;
                }
              }
            }
            .profile-menu {
              margin: 0;
              padding: 0;
              li {
                display: block;
                margin: 0;
                &:last-child a {
                  background: #ffefef !important;
                  border-radius: 0 0 10px 10px;
                  color: $color-primary !important;
                }
                a {
                  background: $color-initial;
                  color: $color-quaternary;
                  display: block;
                  font-size: 14px;
                  padding: 10px 33px;
                  &:hover {
                    background: #f4f4fd;
                    color: $color-secondary;
                  }
                }
              }
            }
          }
        }
        &.nav-search {
          margin-left: 23px;
          position: relative;
          > img {
            cursor: pointer;
            display: block;
            height: auto;
            padding: 43px 0 26px;
            width: auto;
          }
          .nav-search-box {
            background: #fff;
            border: none;
            border-radius: 10px;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
            display: none;
            min-height: 100px;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: -15px;
            top: calc(100% + 18px);
            width: 230px;
            z-index: 3;
            &:before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid $color-secondary;
              border-radius: 2px;
              content: "";
              height: 0;
              position: absolute;
              right: 19px;
              top: -7px;
              width: 0;
            }
            width: 640px;
            height: 65px;
            min-height: 0;
            margin-top: 20px;
            form {
              height: 100%;
              position: relative;
              input[type="text"] {
                border: 0;
                border-radius: 5px;
                color: #333;
                font-size: 16px;
                line-height: 100%;
                height: 100%;
                padding: 0 75px 0 25px;
                position: relative;
                width: 100%;
              }
              input[type="submit"] {
                background: $color-secondary;
                border: none;
                border-radius: 0 10px 10px 0;
                color: #fff;
                cursor: pointer;
                font-size: 14px;
                height: 100%;
                margin-bottom: 0;
                padding: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;
                width: 120px;
                transition: all 0.25s ease-in-out;
              }
            }
          }
        }
        &.nav-cart {
          margin-left: 32px;
          .button {
            color: $color-initial;
            margin: 26px 0 26px;
            height: 50px;
            line-height: 50px;
            padding: 0 25px;
            > img {
              margin-right: 8px;
              position: relative;
              top: 13px;
            }
          }
        }
        &.dropdown {
          position: relative;
          &:hover ul {
            display: block;
          }
          ul {
            display: none;
            left: 50%;
            margin: 0;
            min-width: 350px;
            padding-top: 19px;
            position: absolute;
            top: 100%;
            width: auto;
            z-index: 9999;
            @include transform(translateX(-50%));
            &:before {
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 7px solid $color-initial;
              border-radius: 2px;
              content: "";
              height: 0;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              top: 13px;
              width: 0;
            }
            li {
              display: block;
              margin-bottom: 0;
              &:first-child a {
                border-radius: 10px 10px 0 0;
              }
              &:last-child a {
                border-radius: 0 0 10px 10px;
              }
              a {
                background: $color-initial;
                color: $color-quaternary;
                display: block;
                font-size: 17px;
                font-weight: 500;
                padding: 28px 20px 28px 73px;
                position: relative;
                border-bottom: 1px solid #f1f1f1;
                > img {
                  position: absolute;
                  left: 20px;
                  top: 50%;
                  @include transform(translateY(-50%));
                }
                &:hover,
                &.active {
                  background: #f4f4fd;
                  color: $color-secondary;
                }
              }
              &.no-icon a {
                padding: 1.5rem;
                text-align: center;
                color: $color-primary;
              }
            }
          }
        }
      }
    }
  }
  .burger-menu {
    display: none;
  }
  .menu-mobile {
    background: $color-initial;
    bottom: 0;
    margin-right: -580px;
    opacity: 0;
    padding: 24px 63px 109px 60px;
    position: fixed;
    right: 0;
    top: 80px;
    visibility: hidden;
    width: 580px;
    z-index: 9999;
    transition: 0.4s all ease;
    &.active {
      margin-right: 0px;
      opacity: 1;
      visibility: visible;
      transition: 0.4s all ease;
    }
    > ul {
      height: 100%;
      overflow-x: auto;
      padding-right: 44px;
      &::-webkit-scrollbar-track {
        background-color: #e5e5e5;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar {
        background-color: #e5e5e5;
        width: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-moz-scrollbar-track {
        background-color: #e5e5e5;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      }
      &::-moz-scrollbar {
        background-color: #e5e5e5;
        width: 1px;
      }
      &::-moz-scrollbar-thumb {
        background-color: $color-primary;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      }
      &::-ms-scrollbar-track {
        background-color: #e5e5e5;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      }
      &::-ms-scrollbar {
        background-color: #e5e5e5;
        width: 1px;
      }
      &::-ms-scrollbar-thumb {
        background-color: $color-primary;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-o-scrollbar-track {
        background-color: #e5e5e5;
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
      }
      &::-o-scrollbar {
        background-color: #e5e5e5;
        width: 1px;
      }
      &::-o-scrollbar-thumb {
        background-color: $color-primary;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      > li {
        border-bottom: 1px solid #e2e2e2;
        display: block;
        margin-bottom: 0;
        position: relative;
        &:first-child > a {
          padding-top: 0;
        }
        &.dropdown {
          &:after {
            content: url(../images/material/ic-chevron-bottom.svg);
            position: absolute;
            right: 0;
            top: -2px;
          }
          &.active:after {
            content: url(../images/material/ic-chevron-top.svg);
          }
        }
        &.language {
          align-items: center;
          border: 0;
          display: flex;
          gap: 15px;
          padding: 15px 0;
          a {
            display: inline-block;
            padding: 0;
            &.active {
              color: #e21f26;
              font-weight: bold;
            }
          }
        }
        > a {
          color: $color-quaternary;
          display: block;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          padding: 24px 60px 24px 0;
        }
        > ul {
          display: none;
          margin: 0 0 10px 20px;
          li {
            display: block;
            margin-bottom: 0;
            &.no-icon a {
              color: $color-primary;
              padding-left: 0;
            }
            a {
              background: $color-initial;
              color: $color-quaternary;
              display: block;
              font-size: 16px;
              font-weight: 500;
              padding: 12px 0 12px 60px;
              position: relative;
              > img {
                position: absolute;
                left: 0;
                top: 50%;
                @include transform(translateY(-50%));
              }
              &:hover {
                background: #f4f4fd;
                color: $color-secondary;
              }
            }
          }
        }
      }
    }
  }
}

/* content
----------------------------------------------------------------------------------------------*/
.ui-datepicker {
  z-index: 10 !important;
}

*,
::after,
::before {
  box-sizing: border-box;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
body {
  overflow-x: hidden;
  padding-top: 100px;
  &.no-pad {
    padding: 0;
  }
}
figure {
  margin: 0;
}
label.checkbox-form,
label.light {
  font-weight: 300;
}
.form-group.mb-0 {
  margin-bottom: 0;
}
.overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9998;
}
.overlay-helpmenu {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.wrapper {
  margin: 0 auto;
  position: relative;
  width: 1220px;
}
.wrapper-small {
  margin: 0 auto;
  position: relative;
  width: 80.8rem;
}
.banner {
  .item {
    position: relative;
    figure {
      position: relative;
      &:before {
        background: rgba(0, 0, 0, 0.2);
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
      img {
        display: block;
        height: 100%;
        max-height: 660px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
    .wrapper {
      left: 0;
      position: absolute;
      top: calc(50% - 50px);
      right: 0;
      @include transform(translateY(-50%));
    }
    .item-text {
      padding-left: 80px;
      width: 631px;
      img {
        margin-bottom: 20px;
      }
      h1 {
        color: $color-initial;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        margin-bottom: 19px;
      }
      p {
        color: $color-initial;
        font-size: 23px;
        font-weight: 300;
        line-height: 36px;
        margin-bottom: 20px;
      }
      .button {
        height: 4rem;
        line-height: 4rem;
        margin-bottom: 0;
      }
    }
  }
}
.banner-small {
  .item {
    position: relative;
    figure {
      position: relative;
      &:before {
        background: $color-secondary;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
      img {
        display: block;
        height: 387px;
        object-fit: cover;
        object-position: center;
        opacity: 0.2;
        position: relative;
        width: 100%;
        z-index: 2;
      }
    }
    .wrapper {
      left: 0;
      position: absolute;
      top: 143px;
      right: 0;
      z-index: 4;
    }
    .item-text {
      margin: 0 auto;
      text-align: center;
      width: 482px;
      h1 {
        color: $color-initial;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        margin-bottom: 0;
      }
    }
  }
}
.bottom-background {
  margin-top: -50px;
  img {
    height: auto;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
.no-pad {
  padding: 0;
}
.sumary-text {
  margin: -150px auto 34px;
  .wrapper {
    background: $color-initial;
    border-radius: 20px 20px 0 0;
    border-bottom: 1px solid #e2e2e2;
    padding: 77px 206px 37px 206px;
  }
  article {
    position: relative;
    &:before {
      background: $color-primary;
      content: "";
      height: 65px;
      left: -52px;
      position: absolute;
      top: 10px;
      width: 1px;
    }
    .title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 43px;
      padding-bottom: 0;
      &:before {
        display: none;
      }
    }
    p {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 0;
    }
  }
}
.std-content {
  .red {
    color: $color-primary;
  }
  h1 {
    font-weight: bold;
  }
  p {
    font-size: 16px;
    line-height: 30px;
  }
  ul {
    margin: 10px 0 20px;
    > li {
      font-size: 16px;
      line-height: 30px;
      margin: 0;
      padding-left: 25px;
      position: relative;
      &:before {
        background: $color-primary;
        border-radius: 50%;
        content: "";
        height: 6px;
        left: 0;
        position: absolute;
        top: 13px;
        width: 6px;
      }
    }
    &.alphabet {
      counter-reset: list;
      > li {
        &:before {
          background: transparent;
          color: $color-primary;
          counter-increment: list;
          content: counter(list, upper-alpha) ". ";
          height: unset;
          top: 1px;
        }
      }
    }
  }
  ol {
    counter-reset: li;
    list-style: none;
    margin: 0 0 30px 0;
    > li {
      counter-increment: li;
      font-size: 16px;
      line-height: 30px;
      margin: 0;
      padding-left: 30px;
      position: relative;
      &:before {
        content: counter(li);
        color: $color-primary;
        display: block;
        font-size: 16px;
        line-height: 30px;
        left: 0;
        position: absolute;
        top: 1px;
      }
    }
  }
  > img {
    height: auto;
    margin-bottom: 20px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
  figure {
    margin-bottom: 20px;
    > img {
      height: auto;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  iframe {
    height: 560px !important;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
  }
  .row {
    align-content: center;
    align-items: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 50px 0 100px;
    .box-half {
      width: 50%;
      &:first-child {
        padding-right: 50px;
      }
      &:last-child {
        padding-left: 50px;
      }
      .content-img {
        text-align: right;
      }
    }
  }
  .table {
    overflow: auto;
    width: 100%;
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #bbb;
    }
    &::-moz-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-moz-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-moz-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #bbb;
    }
    &::-o-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-o-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #f5f5f5;
    }
    &::-o-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #bbb;
    }
    .table {
      width: 100%;
    }
  }
  table {
    td {
      p {
        margin: 0;
        line-height: 22px;
        span {
          font-size: 15px !important;
        }
      }
      ol {
        margin: 0;
        li {
          line-height: 25px;
          &:before {
            line-height: 25px;
          }
          span {
            font-size: 15px !important;
          }
        }
      }
    }
  }
}
.title {
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 31px;
  max-width: 100%;
  padding-bottom: 19px;
  position: relative;
  text-align: left;
  &:before {
    background: $color-primary;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 87px;
  }
}
.title-section {
  display: inline-block;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 16px;
}
.paging {
  margin: 30px auto;
  padding: 0;
  text-align: center;
  li {
    display: inline-block;
    margin: 0;
    &:first-child {
      margin-right: 40px;
      a {
        cursor: pointer;
        width: auto;
        &:hover {
          background: transparent;
          color: $color-secondary;
          opacity: 1;
        }
      }
    }
    &:last-child {
      margin-left: 40px;
      a {
        cursor: pointer;
        width: auto;
        &:hover {
          background: transparent;
          color: $color-secondary;
          opacity: 1;
        }
      }
    }
    a {
      border-radius: 5px;
      color: $color-tertiary;
      font-size: 16px;
      font-weight: 500;
      height: 40px;
      display: block;
      padding: 8px;
      text-align: center;
      width: 40px;
      &.disabled {
        color: #a1a1a1;
        font-size: 16px;
        font-weight: 500;
        pointer-events: none;
      }
      &.active {
        background: $color-secondary;
        color: $color-initial;
      }
      &:hover {
        background: $color-secondary;
        color: $color-initial;
        opacity: 0.8;
      }
    }
  }
}
.group-btn {
  margin-left: 10px;
  padding: 0;
  position: relative;
  &.active {
    background: $color-primary;
    border-color: $color-primary;
  }
  > span {
    display: block;
    padding: 0 4rem 0 2rem;
    position: relative;
    &:after {
      content: url("../images/material/ic-chevron-bottom-white.svg");
      position: absolute;
      right: 13px;
      top: 0;
    }
  }
  > .list-download {
    // background: transparentize($color-secondary, 0.7);
    background: rgba(36, 34, 119, 0.7);
    border-radius: 8px;
    display: none;
    left: 0;
    max-height: 138px;
    min-width: 160px;
    overflow: auto;
    position: absolute;
    top: 120%;
    a {
      display: block;
      font-size: 14px;
      line-height: normal;
      padding: 15px 15px;
      text-align: left;
    }
  }
}

.select2-container--open {
  z-index: 11000;
}

.form-group .select2-container .select2-selection--single {
  border: none !important;
  border-bottom: 1px solid #e5e5e5 !important;
}
.wrap-data-polis {
  .form-group .select2-container .select2-selection--single {
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }
}

.row .column.box-country {
  display: none;
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
  padding: 20px 0 50px;
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .foot-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .foot-left {
    padding-top: 15px;
    // width: calc(100% - 343px);
    order: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .ojk {
      color: $color-quaternary;
      display: block;
      font-size: 15px;
      margin-bottom: 0;
      text-align: right;
      width: 100%;
    }
    .social-media {
      margin-right: 40px;
      span {
        color: $color-quaternary;
        display: block;
        font-size: 14px;
        margin-bottom: 16px;
      }
      a {
        display: inline-block;
        margin-right: 18px;
      }
    }
    .foot-sponsor {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 10px;
      li {
        margin-left: 28px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .foot-right {
    width: 343px;
    order: 1;
  }
  .foot-bottom {
    width: calc(100% - 343px);
    .sitemap {
      @include afterclear;
      margin-bottom: 14px;
      li {
        float: left;
        margin-bottom: 0;
        margin-right: 15px;
        a {
          color: $color-quaternary;
          display: block;
          font-size: 15px;
        }
        + li {
          padding-left: 17px;
          position: relative;
          &:before {
            content: "|";
            color: $color-quaternary;
            display: block;
            font-size: 15px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .copyright {
      color: $color-quaternary;
      display: block;
      font-size: 14px;
      a {
        color: #555555;
        font-weight: 700;
      }
    }
  }
}
