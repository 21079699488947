.section-account{
	padding: 6rem 0;
	.wrapper{
		padding: 0 7.5rem;
	}
}

.bg-grey{
	background: #F4F4F6;
	min-height: calc(100vh - 10rem);
}

.box-aside{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	aside, article{
		border-radius: 1rem;
	}
	aside{
		flex: 1 0 28%;
		max-width: 28%;
		background: #443C9B;
		color: $color-initial;
		border-radius: 1rem;
		overflow:hidden;
		position: sticky;
    top: 120px;

		a{color: #C7C5E1;}
		.account-aside{
			background: #1B1464;
			border-radius: 1rem 1rem 0 0;
			padding: 2.5rem 2rem;

			> img{
			    float: left;
			    height: 60px;
			    object-fit: cover;
			    object-position: center;
			    margin-right: 19px;
			    width: 60px;
			}
			h5{
				color: $color-initial;
				font-size: 1.8rem;
				font-weight: 500;
				margin-bottom: 0;
				line-height: 1.3;
			}
			span{
				font-size: 1.3rem;
				opacity: .6;
				display: block;
			}
			figcaption{padding-top: .8rem;}
		}

		.title-aside{
			padding: 2.4rem 2.3rem 2.4rem 5.8rem;
			color: $color-initial;
			position: relative;
			background: #39337E;
			>img{
				width: 2.2rem;
				height: 2.2rem;
				margin: auto 0;
				position: absolute; top: 0; bottom: 0; left: 2.3rem;
			}
			h5{
				font-size: 1.6rem;
				font-weight: 500;
				margin-bottom: 0;
			}
		}
	}
	article{
		flex: 1 0 67%;
		max-width: 67%;
		background: $color-initial;

		.title-profile{
			background: #1B1464;
			border-radius: 1rem 1rem 0 0;
			color: $color-initial;
			padding: 1.8rem 2.3rem 1.8rem 6.3rem;
			position: relative;
			font-size: 1.3rem;
			font-weight: 700;
			text-transform: uppercase;

			> img{
				margin: auto 0;
				position: absolute; top: 0; bottom: 0; left: 2.3rem;
			}
			a{color: $color-initial;}
			.button { margin-bottom: 0; margin-left: 10px;}
		}

		.content-profile{
			padding: 3rem 5.3rem;

			form{
				margin-bottom: 0;
				.column{margin-bottom: 0;}
			}

			hr.dashed{
				margin-left: -5.3rem;
				margin-right: -5.3rem;
			}
			hr.soft{
				margin-left: -5.3rem;
				margin-right: -5.3rem;
			}

			.box-rounded-grey{
				hr.dashed{
					margin-left: -3rem;
					margin-right: -3rem;
          &.space-lg {
            margin-left: -6rem;
            margin-right: -6rem;
          }
				}
			}
			.no-data { padding: 30px 0 50px; }
			&.light-padding{
				padding: 2rem;
				hr.dashed{
					margin-left: -2rem;
					margin-right: -2rem;
				}
			}
			&.no-padding{
				padding: 0;
			}
		}
	}
}

.nav-aside{
	margin: 0;

	a{font-size: 1.4rem;}
	> li{
		padding: 2.4rem 2.3rem 2.4rem 5.8rem;
		border-bottom: .1rem solid rgba(255,255,255,.1);
		position: relative;
		margin-bottom: 0;

		> a{
			position: relative;
			display: block;
			font-weight: 500;
			color: $color-initial;
			font-size: 1.6rem;

			> img{
				width: 2.8rem;
				height: 2.8rem;
				margin: auto 0;
				position: absolute; top: 0; bottom: 0; left: -3.7rem;
			}
		}
		> ul{
			margin: 0;
			padding-top: 1.5rem;
			> li{
				> a{
					&:hover{color:$color-initial;}
				}

				&.active{
					> a{font-weight: 500;color: $color-initial;}
				}
			}
		}

		&:last-child{border-bottom: none;}
		&.active{background: #39337E;}
	}
}

.box-avatar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1.1rem;
	border-bottom: .1rem solid #E5E5E5;
	margin-bottom: 3rem;

	.left-avatar{
		display: flex;
		align-items: center;

		img{
			flex: 1 0 6rem;
			max-width: 6rem;
			height: 6rem;
			border-radius: 50%;
			margin-right: 1.8rem;
		}
		span{
			font-size: 1.5rem;
			color: #A1A1A1;
		}
	}
}

.box-polis-perjalanan{
	color: #A1A1A1;
	font-size: 1.3rem;

	h5{
		font-size: 2rem;
		font-weight: 500;
		color: #111111;
	}
	h6{
		font-size: 1.5rem;
		font-weight: 500;
		color: #555555;
	}
	a{
		&.link-with-icon{ color: $color-secondary;
			font-weight: 500;
			&:hover { color: $color-primary; }
			&:after{top:auto; vertical-align: middle;}
		}
	}
	table{
		margin-bottom: 0;
		td, th{
			border:none;
			padding-top: .8rem;
			padding-bottom: .8rem;
			width: 60%;
			b{
				font-size: 1.5rem;
				color: #555555;
			}
		}
	}
	.title-dash { border-bottom: 1px dashed #E5E5E5; font-size: 2.4rem; font-weight: bold; color: #1B1464; padding: 20px 60px; }
	.titles { font-size: 1.6rem; font-weight: bold; color: #1B1464; padding: 0 60px 0; margin-bottom: 20px; }
	.list-polis-perjalanan{
		border: .1rem solid #E5E5E5;
		border-radius: 1rem;
		margin-bottom: 1rem;
	}
	.row{margin-left: 0;width: 100%;}
	.body-polis-perjalanan{
		border-bottom: .1rem solid #E5E5E5;

		.column{padding: 2rem 3rem;}
		.column-36{
			border-right: .1rem solid #E5E5E5;
		}
	}
	.foot-polis-perjalanan{
		.column{
			padding: 1.5rem 3rem;
		}
	}
	.box-soft-orange,.box-asuransi-list { margin: 0 20px; }
	.berlaku-polis {
		form { padding: 0 60px; 
			select { border: 1px solid #E5E5E5; padding-right: 50px; min-width: 250px; width: auto; }
		}
	}
	.btn-action { margin: 30px 0 40px; text-align: center; }
}

input.search-grey{
	background: #F4F4F6 url('../images/material/icon-search-2.svg')no-repeat center right;
	border-radius: 1rem;
	border: none;
	padding: 0 6rem 0 3rem;
	color: #A1A1A1;
	font-size: 1.5rem;
	height: 4.5rem;
	margin-bottom: 2rem;

	@include andplaceholder{
		color: #A1A1A1;
	}
}

.tab-polis{
	.btn-tab{
		padding: 2.4rem 2rem 0;
		border-bottom: .1rem solid #E5E5E5;
		li {
			margin-right: 5rem;
			display: inline-block;
			vertical-align: middle;
		}
		a{
			color: #555555;
			padding-bottom: 1.4rem;
			font-size: 1.5rem;
			font-weight: 500;
			position: relative;

			&:before{
				content:"";
				height: .1rem;
				background: #D7001B;
				width: 0%;
				margin: 0 auto;
				transition: .2s width ease-out;
				position: absolute; right: 0; bottom: 0; left: 0;
			}
			&:last-child{margin-right: 0;}
			&:hover, &.active{
				&:before{
					width: 100%;
				}
			}
			&.active{color: #D7001B;}
		}
	}
	.list-content-tab{
		padding: 2rem;
		display: none;

		h4{margin-bottom: 0;}
		p{color: #999999;line-height: 1.5;}
		&.active{
			display: block;
		}
	}
}

.box-list-info{
	.list-info{
		position: relative;
		padding: 2.5rem 20rem 2.5rem 9rem;
		background: #F4F4F6;
		border-radius: 1rem;
		font-size: 1.3rem;
		margin-bottom: 1.5rem;

		h6{font-weight: 700;margin-bottom: 0;}
		span{display: block;}
		> img{
			position: absolute; top: 2.5rem; left: 3rem;
		}
		.button{
			font-size: 1.3rem;
			padding: 0 2rem;
			position: absolute; top: 2.5rem; right: 3rem;
		}
	}
}

.box-photo-upload{
	background: #F4F4F6;
	border-radius: 1rem;
	padding: 2.7rem 5rem 2.7rem 10rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;

	b{ font-size: 1.3rem;font-weight: 700; }
	h6{ font-size: 1.5rem;margin-bottom: 0; }
	> img{
		border-radius: 1rem;
		position: absolute; top: 2rem; left: 1.5rem;
	}
}

.btn-delete{
	display: inline-block;
	vertical-align: middle;
	color: #555555;

	&:before{
		content:"";
		display: inline-block;
		width: 2rem;
		height: 2rem;
		margin-bottom: -.5rem;
		background: url('../images/material/icon-delete.svg')no-repeat center;
	}

	&:hover{
		color: $color-primary;
	}
}

.box-dash-rounded{
	border-radius: 1rem;
	border: .1rem dashed #E5E5E5;
	padding: 2.9rem 2rem;
	margin-bottom: 2.8rem;
}

.box-polis-list{
	.polis-list{
		border: .1rem solid #E5E5E5;
		border-radius: 1rem;
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2rem 3rem;

		h5{font-size: 2rem;margin-bottom: 0;font-weight: 500;}
		.text-rounded-list{
			flex: 1 0 70%;
			span{font-size: 1.3rem;color: #A1A1A1;display: block;}
		}
		a.link-with-icon{
			color: #555555;
			font-size: 1.3rem;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
}

.title-pemegangpolis{
	position: relative;
	padding: 0 0 0 6rem;
	> img{
		position: absolute; top: -.5rem; left: 0;
	}
	h5{font-weight: 500;margin-bottom: 0;color: #39337E;}
}