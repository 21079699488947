/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
@import "helper/sass-lib";
@import "helper/flexbox";

/* milligram
----------------------------------------------------------------------------------------------*/
@import "milligram/milligram";

/* pages
----------------------------------------------------------------------------------------------*/
@import "pages/general";
@import "pages/home";
@import "pages/product";
@import "pages/help";
@import "pages/contact-us";
@import "pages/promotion";
@import "pages/misc";
@import "pages/login";
@import "pages/account";
@import "pages/career";
@import "pages/transaction";
@import "pages/popup";

/* pages responsive desktop
----------------------------------------------------------------------------------------------*/
@import "pages_desktop/general";
@import "pages_desktop/home";
@import "pages_desktop/login";

/* pages responsive tablet
----------------------------------------------------------------------------------------------*/
@import "pages_tablet/general";
@import "pages_tablet/home";
@import "pages_tablet/product";
@import "pages_tablet/help";
@import "pages_tablet/contact-us";
@import "pages_tablet/promotion";
@import "pages_tablet/misc";
@import "pages_tablet/login";
@import "pages_tablet/account";
@import "pages_tablet/career";
@import "pages_tablet/transaction";
@import "pages_tablet/popup";

/* pages responsive mobile
----------------------------------------------------------------------------------------------*/
@import "pages_mobile/general";
@import "pages_mobile/home";
@import "pages_mobile/product";
@import "pages_mobile/help";
@import "pages_mobile/contact-us";
@import "pages_mobile/promotion";
@import "pages_mobile/misc";
@import "pages_mobile/login";
@import "pages_mobile/account";
@import "pages_mobile/career";
@import "pages_mobile/transaction";
@import "pages_mobile/popup";