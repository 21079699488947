@media (max-width: 767px) {
	.section-login { overflow: auto;
		> figure { height: 160px; 
			figcaption { bottom: 3rem; font-size: 1.9rem; left: 3rem; line-height: 2.8rem; right: 3rem; }
		}
		.inner-login { padding-top: 3rem; 
			.center-login { width: 85%; }
			.link-underline { display: block; font-size: 13px; line-height: 24px; margin: 0 auto -15px; text-align: center; } 
			.divider-text { font-size: 13px; line-height: 24px; }
			.text-soft { font-size: 13px; line-height: 24px; }
			form {
				.row { flex-wrap: wrap;
					.column.column-50{ flex: 0 0 100%; margin-bottom: 30px; max-width: 100%; 
						&:last-child { margin-bottom: 0; }
					}
				}
			}
			.button.button-outline-soft,
			button.button-outline-soft,
			input[type='button'].button-outline-soft,
			input[type='reset'].button-outline-soft,
			input[type='submit'].button-outline-soft { margin-bottom: 20px; padding: 0 30px; text-align: center; 
				> img { left: -10px; position: relative; }
			}
		}
	}
	.section-login.type2 {
		.logo { padding: 1.2rem 3rem 1.2rem 3rem; }
		.inner-login { padding-bottom: 0; }
		.center-login {
			h4 { font-size: 17px; line-height: 30px; }
			a { display: inline-block; }
		}
		.foooter { height: auto; font-size: 13px; padding: 30px;
			br {display: none;}
			&:after { display: none; }
		}
	}
}