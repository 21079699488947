@media (max-width: 1279px){
	.terms-and-condition {
		.wrapper { padding: 0; }
		.button, input[type="submit"] { min-width: 190px; }
	}
	.search-result {
		.wrapper { padding: 0; }
		.search-result-form { margin-bottom: 56px;
			form input[type="text"] { font-size: 19px; padding: 29px 40px; }
		}
	}
	.page-404 {
		.img { height: auto; margin-bottom: 16px; width: 280px; }
		.title { font-size: 32px; margin-bottom: 26px; padding-bottom: 21px; }
	}
	.underconstruction .text {
		img { height: 48px; margin-bottom: 0; width: auto; }
		.title { font-size: 32px; margin-bottom: 26px; padding-bottom: 21px; }
	}
}
@media (max-width: 991px){
	.terms-and-condition { padding-bottom: 30px;
		.title { margin-bottom: 60px; }
	}
	.search-result {
		.title { margin-bottom: 40px; }
		.search-result-form { margin-bottom: 86px;
			form {
				input[type="text"] { font-size: 16px; height: 60px; padding: 18px 40px; }
				button { height: 60px; }
			}
		}
		.search-list { margin-top: 43px; }
	}
	.page-404 {
		p { width: 80%; }
	}
	.underconstruction {
		figure { width: 60%; }
		.text { margin-left: -5%; width: 40%; 
			img { margin-bottom: 20px; }
			.title { font-size: 23px; line-height: 34px; padding-bottom: 24px; margin-bottom: 30px; 
				&:before { width: 40px; }
			}
			p { font-size: 14px; line-height: 30px; }
		}
	}
	.ty-page { width: 100%; }
}