@media (max-width: 1279px){
	.section-account .wrapper { padding: 0; }
	.box-aside { flex-direction: row; flex-wrap: wrap;
		aside { flex: 0 0 100%; margin-bottom: 20px; max-width: 100%;
			.step-transaction { 
				align-items: stretch; display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; 

				&.tri-step {
					.list {
						h5, &.done h5 { display: block; }
						&:last-child { padding-right: 5rem; }
					}
				}
				.list { 
					padding-right: 1.5rem;
					flex: 1 1 auto;
					max-width: 33.333%;
					&:last-child { padding-right: 3rem; 
						&.active { padding-right: 3rem; }
					}
					&.active { padding-right: 0;
						h5 { display: block; }
					}
					&.done h5 { display: none; }
					h5 { display: none; }
					.num-step { top: 50%; @include transform(translateY(-50%)); }
				}
			}
		}
		article { flex: 0 0 100%; max-width: 100%; 
			.content-profile { padding: 3rem 6rem; 
				&.notable .row.row-center { margin: 0 -3rem; width: calc(100% + 6rem); 
					.column { padding: 0; }
				}
				.center-align { margin: 0 -3rem; width: calc(100% + 6rem); 
					.button,button { display: block; margin: 0 auto; width: calc(100% - 6rem); }
				}
				form > h4 { font-size: 20px; line-height: 30px; 
					b { font-weight: 400; }
				}
			}
			input[type='submit'] { width: 100%; }
		}
	}

  .summary {
    padding-bottom: 0; 
  }
	.need-help,
	.need-help-mobile { 
    padding: 1.2rem 2.8rem; @include afterclear;
		h5 { display: inline-block; margin-bottom: 0; margin-right: 100px; }
		p { display: inline-block; margin-bottom: 0; opacity: .8; }
	}
  .need-help {
    display: none; 
  }
  .need-help-mobile {
    margin: 2rem -3.3rem 0;
    display: block; 
  }
  .total-desktop {
    display: none;
  }
  .total-mobile {
    display: block;
    padding: 0 3.3rem; 
    margin-bottom: -38px;
    .row {
      .column {
        margin: 0; 
        padding-top: 16px; 
        &:nth-child(2) {
          text-align: right;
        }
        h5 {
          line-height: 1.3;
          font-weight: 300;
          margin-bottom: 0;
          font-size: 1.6rem; 
        }
      }
    }
  }

	.promo-box { margin-bottom: 4rem; }
	.box-asuransi-list .list-asuransi {
		.title-asuransi { justify-content: space-between;
			table tr th, table tr td { padding: 0 2.5rem; }
		}
		.content-asuransi table th, .content-asuransi table td { color: #555555; font-weight: normal; }
	}
	.box-total-asuransi .top-total-asuransi .right-total { flex: 0 0 45%; }
	.tab-rounded .content-tab-rounded .list-tab > img { width: 100%; }
	.title-icon { margin-bottom: 3.0rem;
		img { height: 32px; margin-right: 16px; position: relative; top: 6px; width: auto; }
		h2 { font-size: 2.4rem; margin-bottom: 0; }
	}
	.title-dash { margin-left: -3rem; 
		h4 { font-size: 17px; }
	}
	.review-text h4 { font-size: 19px; }
	.section-account {
		.box-aside {
			.floating {
				position: sticky;
				top: 80px;
				z-index: 2;
				.summary {
					display: none;
				}
			}
		}
	}
	.triger-show-floating {
		display: flex;
		padding: 1.6rem 3.3rem;
		position: relative;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border-top: 0.1rem solid rgba(229, 229, 229, 0.1);
		border-bottom: 0.1rem solid rgba(229, 229, 229, 0.1);
		&.open {
			.ico {
				transition: all ease-in-out .25s;
				transform: rotate(180deg);
			}
		}
		span {
			font-weight: 400;
			font-size: 1.3rem;
			margin-bottom: 4px;
		}
		.ico {
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #E21F26;
			background: #E21F26;
			border-radius: 50%;
			transition: all ease-in-out .25s;
			img {
				display: block;
				width: 10px;
			}
		}
	}
}
@media (max-width: 991px) {
	.box-aside aside {
		.step-transaction .list { border: none; border-right: 0.1rem solid rgba(229, 229, 229, 0.1); min-height: 83px; padding: 2rem 0rem 2rem 10rem;
			&:last-child { padding-right: 0rem; }
			&.active { padding-right: 2.5rem	;
				h6 { display: block; }
			}
			h6 { display: none; font-size: 1.3rem; }
			h5 { font-size: 1.5rem; }
		}
	}
	.section-account .box-aside {
		aside { margin-bottom: 90px; 
			&.trans { margin-bottom: 30px; }
		}
		article .content-profile {
			form.form2 .form-group label { margin-bottom: 2rem; }
		}
	}
	.box-aside article .content-profile { padding: 3rem 4.2rem; 
		.center-align { margin: 0; width: 100%; }
		&.notable {
			> h5 { font-size: 1.7rem; margin-left: 3rem; }
			.row.row-center { margin: 0; width: 100%; }
		}
	}
	.title-dash { margin-left: 0; }
	.box-checkbox-box { 
    .checkbox-box {
      input {
        + .inner-checkbox img {
          margin-bottom: 1rem;
        }
      }
      &.check-white {        
        margin-right: 10px;
        margin-bottom: 10px; 
        &:last-child {
          margin-right: 0; 
        }
        .inner-checkbox {
          width: 128px;
          height: 128px;
        }
      }
      &.check-package {
        margin-right: 10px;
        margin-bottom: 10px; 
        &:last-child {
          margin-right: 0; 
        }
        .inner-checkbox {
          width: 175px; 
        }
      }
    }
  }
  .add-item {
    margin-left: 0;
    margin-right: 0; 
  }
	.box-asuransi-list .list-asuransi .title-asuransi {
		table tr th,
		table tr td { padding: 0 2.3rem 0 0; }
		.button { margin-top: 2px; }
	}
	.box-leisure .list-leisure {
		p { margin-bottom: 0; }
		.left-lei { padding-right: 3rem; }
		.right-lei { flex: 1 1 30%; }
	}
	.box-rounded-grey { 
    margin: 0 0 40px; 
		h4,h5 { font-size: 1.7rem; font-weight: 500; margin-left: 0 !important; }
		.row { margin-left: -1.5rem; width: calc(100% + 3rem);
			.column { padding: 0 1.5rem; }
		}
    &.box-accordion {
      .box-rounded-dashed-grey {
        margin: 0 -3rem 0; 
        border-radius: 0; 
        border: 0;
        border-bottom: 0.1rem dashed #e8e8e8; 
      }
    }
	}
	.box-photo-upload { padding: 2rem 4rem 2rem 9rem; 
		> img { top: 1.4rem; }
	}
	.box-total-asuransi { margin: 0 0 3rem; 
		.top-total-asuransi {
			.left-total { flex: 0 0 45%; max-width: 45%; }
			.right-total { flex: 0 0 55%; max-width: 55%; }
		}
	}
	.box-rounded-dashed-grey { margin: 0 0 2rem; }
}