@media (max-width: 767px) {
	.search-box form { border-radius: 20px; 
		input { font-size: 16px; line-height: 30px; }
	}
	.help {
		aside {
			> span { font-size: 16px; line-height: 30px; padding-bottom: 11px; 
				&:after { top: 2px; }
				&.active:after { top: -2px; }
			}
			.tab { top: 60px; 
			 	> li {
		 			> a { font-size: 14px; line-height: 28px; }
			 		> ul > li { margin-bottom: 18px;
			 			> a { font-size: 14px; line-height: 28px; }
			 		}
			 	}
			}
		}
		.tab-content .std-content {
			.title { border-bottom: 1px solid #d5d5d5; font-size: 14px; line-height: 24px; margin-bottom: 19px; padding-bottom: 19px;
				&:before { display: none; }
			}
			.text {
				h5 { font-size: 14px; line-height: 24px; }
				p { font-size: 13px; line-height: 20px; }
			}
		}
	}
	.no-data {
		h4 { font-size: 16px; }
	}
}