@media (max-width: 1279px){
	.banner-slider {
		.item {
			figure img { height: auto; min-height: 500px; max-height: 700px; }
			.item-text { padding-left: 0; width: 60%;
				h2 { font-size: 32px; margin-bottom: 30px; }
				p  { font-size: 19px; line-height: 30px; }
				.link-with-icon { font-size: 15px; }
			}
		}
		.slick-arrow {
			&.arrow-prev { left: 20px; }
			&.arrow-next { right: 20px; }
			svg { height: 36px; width: auto; }
		}
	}
	.product-highlight {
		.highlight { margin-bottom: 60px; margin-top: -140px; 
			figure { width: 248px; }
			.text { padding: 27px 210px 27px 32px; width: calc(100% - 248px);
				.button-blue { right: 32px; top: 51px; }
			}
		}
		.about-product { margin: 60px -24px 0; 
			.item { margin: 0 12px; 
				img { height: auto; width: 76px; }
				.text { width: calc(100% - 76px - 15px);
					h4 { font-size: 15px; margin-top: 2px; }
					p { font-size: 13px; }
				}
			}
		}
	}
	.our-product { margin-bottom: 30px; padding: 60px 0 80px; 
		.product-slider .item {
			figure img { max-width: 216px; height: 250px; object-fit: cover; }
			.text { padding: 0 30px 0 16px; width: 50%; 
				img { margin-bottom: 15px; }
				h4 { font-size: 17px; margin-bottom: 12px; }
				p { line-height: 22px; }
				a { font-size: 13px;}
			}
		}
	}
	.info { padding: 50px 0 140px; 
		> .background { object-fit: cover; }
		.text {
			h4 { font-size: 19px; line-height: 30px; }
			p { font-size: 15px; line-height: 30px; }
			ul li { min-width: auto; }
		}
	}
	.promotion .promotion-list { margin: 0 -8px; 
		.highlight { margin: 0 8px; padding: 40px; 
			img { height: auto; width: 32px; }
			p { font-size: 15px; margin-bottom: 10px; opacity: .7; }
		}
		.item-list {
			.item { margin: 0 8px;
				figure { margin-bottom: 16px; }
				h4 { font-size: 15px; line-height: 24px; }
			}
		}
	}
	.blog .blog-list { grid-gap: 18px 20px; grid-template-columns: calc(50% - 20px);
		.item { align-items: center;
			&:first-child { grid-row: 1 / 3;
				.text { padding: 24px 30px; 
					.date { font-size: 13px; top: 6px; }
					h4 { margin-bottom: 0; }
				}
			}
			figure { height: 100%; }
			.text { padding: 0 0 0 24px;
				.date { color: #9C9C9C; font-size: 13px; }
				h4 { font-size: 15px; line-height: 22px; margin-bottom: 30px; }
				p { display: none; }
				a { font-size: 13px; }
			}
		}
	}
	.contact-me {
		.text { top: 120px;
			h3 { font-size: 19px; margin-bottom: 15px; }
			p { font-size: 23px; line-height: 30px; }
		}
		form { flex-wrap: wrap; margin: -50px 0 0; padding: 30px 43px 28px;
			.input-field { padding-right: 30px; width: calc(100% / 4);
				&:nth-last-child(2) { padding-right: 0; }
				&:last-child { width: 100%; 
					button { margin-top: 24px; width: 100%; }
				}
				input { margin-bottom: 0; }
			}
		}
	}
	.testimonial { padding: 76px 0 0; 
		.title-section { margin-bottom: 39px; }
		.testimonial-slider { padding: 30px 27px 39px;
			.item {
				h4 { font-size: 17px; line-height: 30px; }
				p, h5 { font-size: 13px; line-height: 22px; }
			}
		}
	}
}
@media (max-width: 991px){
	.banner-slider {
		.item {
			.item-text {
				h2 { font-size: 28px; line-height: 40px; margin-bottom: 22px; }
				p { font-size: 16px; line-height: 30px; margin-bottom: 30px; }
			}
		}
		.slick-arrow { top: 330px; }
	}
	.product-highlight { padding-bottom: 90px;
		.highlight { margin-top: -95px;
			figure { width: 202px; }
			.text { width: calc(100% - 202px);
				h3 { font-size: 14px; line-height: 24px; margin-bottom: 0; }
				p { display: none; }
				.button-blue { font-size: 1.4rem; padding: 0 26px; right: 30px; top: 50%; @include transform(translateY(-50%)); }
				.assurance > span { font-size: 1.4rem; padding-left: 35px;
					> img { height: 21px; width: auto; }
				}
			}
		}
		.about-product { margin: 60px 0 0; 
			.item { margin: 0 25px;
				img { float: none; margin-bottom: 10px; }
				.text { float: none; width: 100%; 
					h4 { font-size: 14px; line-height: 24px; text-transform: uppercase; margin-bottom: 5px; }
				}
			}
		}
	}
	.our-product { margin-bottom: 0;
		.product-slider .item {
			figure img { max-width: 230px; height: 207px; }
			.text {
				width: 45%; padding: 0 21px;
				img { margin-bottom: 10px; }
				h4{ font-size: 15px; line-height: 24px; margin-bottom: 20px; }
				p { display: none; }
				&::before { left: -162px; }
			}
		}
	}
	.promotion { padding: 60px 0 0;
		.promotion-list {
			.highlight { padding: 40px; width: 304px; 
				p { font-size: 14px; line-height: 24px; }
			}
			.item-list { align-items: center; margin: 0 8px; width: calc(100% - 320px);
				.item {
					figure { margin-bottom: 10px; 
						img { max-height: 223px; }
					}
					h4 { font-size: 15px; }
				}
				.slick-arrow {
					&.slick-disabled { display: none !important; }
					&.slick-prev { left: 0; }
					&.slick-next { right: 0; }
				}
			}
		}
	}
	.blog { padding: 69px 0 26px; 
		.title-section { margin-bottom: 33px; }
		.blog-list { display: block; margin-top: 0; 
			.item { margin-bottom: 20px;
				&:first-child {
					figure { max-height: 434px; }
					.text { padding: 50px 56px 50px 46px; }
				}
				figure { width: 216px; 
					.tag { font-size: 11px; }
				}
				.text { width: calc(100% - 216px); }
			}
		}
	}
	.contact-me {
		> img { height: 263px; }
		.text { top: 100px; width: 50%;
			h3 { font-size: 17px; line-height: 30px; margin-bottom: 5px; }
			p { font-size: 19px; line-height: 28px; margin-bottom: 0; }
		}
		form { padding: 30px 12px; 
			.input-field { margin: 0 30px; padding-right: 0; width: calc(50% - 60px); 
				input, select { font-size: 14px; }
			}
		}
	}
	.testimonial { padding: 30px 0 50px; 
		.title-section { margin-bottom: 40px; }
		.testimonial-slider { padding: 39px 52px; 
			.item { margin: 0 18px;
				h4 { font-size: 16px; }
				p { font-size: 13px; line-height: 22px; }
				h5 { font-size: 13px; line-height: 22px; }
			}
		}
	}
}