.banner-slider {
  .tg-banner {
    position: absolute;
    top: 25%;
  }
  .item {
    position: relative;
    figure {
      img {
        height: 100%;
        max-height: 660px;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
    .wrapper {
      left: 0;
      position: absolute;
      top: calc(50% - 50px);
      right: 0;
      @include transform(translateY(-50%));
    }
    .item-text {
      padding-left: 80px;
      width: 631px;
      h2 {
        color: $color-initial;
        font-size: 40px;
        font-weight: bold;
        line-height: 50px;
        margin-bottom: 19px;
      }
      p {
        color: $color-initial;
        font-size: 23px;
        font-weight: 300;
        line-height: 36px;
        margin-bottom: 20px;
      }
      .link-with-icon {
        color: $color-initial;
        font-weight: bold;
        text-transform: uppercase;
        &:hover {
          &:after {
            content: url(../images/material/ic-link-red.svg);
          }
        }
      }
    }
  }
  .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: 285px;
    z-index: 10; /*@include transform(translateY(-50%));*/
    &.slick-disabled {
      display: none !important;
    }
    &:hover svg g {
      opacity: 1;
    }
    &.arrow-prev {
      left: 70px;
    }
    &.arrow-next {
      right: 70px;
    }
  }
}
.product-highlight {
  padding-bottom: 76px;
  position: relative;
  .highlight {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 56px;
    margin-top: -150px;
    figure {
      border-radius: 20px 0 0 20px;
      overflow: hidden;
      width: 300px;
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
    .text {
      border: 1px solid #dedede;
      background: $color-initial;
      border-radius: 0 20px 20px 0;
      padding: 39px 280px 20px 80px;
      position: relative;
      width: calc(100% - 300px);
      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 11px;
      }
      p {
        color: $color-quaternary;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 12px;
      }
      .button-blue {
        margin-bottom: 0;
        position: absolute;
        right: 80px;
        top: 65px;
      }
      .assurance {
        position: relative;
        > span {
          display: inline-block;
          cursor: pointer;
          font-size: 15px;
          font-weight: bold;
          height: 40px;
          text-transform: uppercase;
          padding: 10px 30px 15px 50px;
          position: relative;
          > img {
            position: absolute;
            left: 0;
            top: 50%;
            @include transform(translateY(-50%));
          }
          &:after {
            content: url(../images/material/ic-chevron-bottom-light.svg);
            position: absolute;
            right: 0;
            top: 60%;
            @include transform(translateY(-50%));
          }
        }
        .select-assurance {
          background: $color-initial;
          border-radius: 10px;
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
          display: none;
          left: 0;
          position: absolute;
          right: 0;
          top: calc(100% + 19px);
          width: 290px;
          z-index: 99;
          &:before {
            content: url(../images/material/ic-triangle-top.svg);
            left: 0;
            margin-left: 10px;
            position: absolute;
            right: 0;
            top: -13px;
          }
          &.active {
            display: block;
          }
          span {
            cursor: pointer;
            display: block;
            font-size: 15px;
            font-weight: bold;
            padding: 15px 15px 15px 70px;
            position: relative;
            text-transform: uppercase;
            &:hover,
            &.active {
              background: #f4f4fd;
              color: $color-secondary;
              &:last-child {
                background: #f4f4fd;
                border-radius: 0 0 10px 10px;
                color: $color-secondary;
              }
            }
            > img {
              position: absolute;
              left: 15px;
              top: 50%;
              @include transform(translateY(-50%));
            }
            &:last-child {
              padding: 10px 30px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .about-product {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 65px -28px 0;
    .item {
      margin: 0 28px;
      width: calc(100% / 3);
      > img {
        float: left;
        height: auto;
        margin-right: 15px;
        width: 90px;
      }
      .text {
        float: left;
        width: calc(100% - 90px - 15px);
        h4 {
          font-size: 16px;
          font-weight: bold;
          margin: 5px 0 11px;
        }
        p {
          color: $color-quaternary;
          font-size: 15px;
          margin: 0;
        }
      }
    }
  }
}
.our-product {
  background: #f4f4f6;
  padding: 85px 0 90px;
  position: relative;
  .tg-our-product {
    position: absolute;
    top: 30%;
  }
  .link-with-icon.right {
    color: $color-quaternary;
    float: right;
    font-size: 13px;
    font-weight: bold;
    &:hover {
      color: $color-primary;
    }
  }
  .product-slider {
    margin: 0 -10px;
    .item {
      background: $color-initial;
      border-radius: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      margin: 10px 10px 20px 10px;
      overflow: hidden;
      position: relative;
      width: calc(100% / 2);
      figure {
        text-align: right;
        img {
          display: block;
          height: 100%;
          margin-left: 0;
          margin-right: auto;
          max-width: 336px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        right: 0;
        padding: 0 30px;
        position: absolute;
        top: 0;
        width: 43%;
        z-index: 2;
        background: #fff;
        * {
          position: relative;
          z-index: 2;
        }
        > img {
          align-self: flex-start;
        }
        img {
          margin-bottom: 20px;
        }
        h4 {
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 11px;
          align-self: flex-start;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 19px;
        }
        a {
          color: $color-secondary;
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          &:hover {
            color: $color-primary;
          }
        }
        &:before {
          background: url(../images/material/curve-border-white.png) no-repeat
            right center;
          background-size: cover;
          bottom: 0;
          content: "";
          height: 100%;
          left: -163px;
          position: absolute;
          top: 0;
          width: 162px;
          z-index: 1;
        }
        // &:after { background: url(../images/material/ic-heartcurve4.png) no-repeat right center; background-size: cover; bottom: 0; content:  ''; height: 100%; left: 0; position: absolute; right: -40px; top: 0; width: 120%; z-index: 1; }
        // &:after{
        // 	display: none;
        // }
      }
    }
    .slick-arrow {
      cursor: pointer;
      height: 40px;
      position: absolute;
      top: 50%;
      width: 40px;
      z-index: 10;
      @include transform(translateY(-50%));
      &.slick-disabled {
        display: none !important;
      }
      &:hover svg {
        g {
          opacity: 1;
        }
        circle {
          fill: $color-secondary;
          transition: 0.05s all ease;
        }
        path {
          fill: $color-initial;
          opacity: 1;
          transition: 0.05s all ease;
        }
      }
      svg circle,
      svg path {
        transition: 0.05s all ease;
      }
      &.arrow-prev {
        left: -10px;
      }
      &.arrow-next {
        right: -10px;
      }
    }
  }
}
.info {
  padding: 174px 0 157px;
  overflow: hidden;
  position: relative;
  .tg-info {
    position: absolute;
    top: 25%;
  }
  > .background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
  .text {
    width: 540px;
    h3 {
      font-size: 24px;
      font-weight: bold;
      line-height: 34px;
      margin-bottom: 43px;
      padding-bottom: 29px;
      position: relative;
      &:before {
        background: $color-primary;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 50px;
      }
    }
    h4 {
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 24px;
    }
    ul {
      @include afterclear;
      li {
        float: left;
        margin-bottom: 25px;
        min-width: 150px;
        padding-right: 10px;
        width: auto;
      }
      &.type-arrow {
        li {
          display: block;
          float: none;
          margin-bottom: 24px;
          padding-left: 37px;
          position: relative;
          width: 100%;
          &:before {
            content: url(../images/material/ic-link-blue.svg);
            left: 0;
            position: absolute;
            top: 3px;
            @include transform(scale(0.8));
          }
        }
      }
    }
    a {
      color: $color-secondary;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: 14px;
      &:hover {
        color: $color-primary;
      }
    }
  }
}
.promotion {
  padding: 80px 0 0;
  position: relative;
  .tg-promotion {
    position: absolute;
    top: 30%;
  }
  .promotion-list {
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 -16px;
    padding-bottom: 75px;
    .highlight {
      background: $color-secondary;
      border-radius: 20px;
      margin: 0 16px;
      overflow: hidden;
      padding: 60px 43px 40px 57px;
      width: 33.33%;
      img {
        margin-bottom: 12px;
      }
      h3 {
        color: $color-initial;
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 14px;
      }
      p {
        color: $color-initial;
        font-size: 17px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 15px;
      }
      a {
        font-size: 13px;
        font-weight: 500;
        line-height: 14px;
        text-transform: uppercase;
        &:hover {
          color: $color-initial;
        }
      }
    }
    .item-list {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 66.66%;
      .item {
        background: $color-initial;
        border-radius: 20px;
        margin: 0 16px;
        width: calc(50% - 16px);
        a {
          display: block;
        }
        &:hover {
          figure {
            &:before {
              animation: shine 0.75s;
              -webkit-animation: shine 0.75s;
            }
            img {
              transform: scale(1.02);
              transition: 0.3s all ease;
            }
          }
          h4 {
            color: $color-secondary;
          }
        }
        figure {
          border-radius: 0 20px 20px 0;
          margin-bottom: 26px;
          overflow: hidden;
          position: relative;
          &:before {
            background: -webkit-linear-gradient(
              left,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.3) 100%
            );
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.3) 100%
            );
            content: "";
            display: block;
            left: -75%;
            position: absolute;
            top: 0;
            transform: skewX(-25deg);
            -webkit-transform: skewX(-25deg);
            height: 100%;
            width: 50%;
            z-index: 2;
          }
          img {
            display: block;
            height: 100%;
            width: 100%;
            transition: 0.3s all ease;
          }
        }
        h4 {
          color: $color-tertiary;
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  }
}
.blog {
  padding: 83px 0 20px;
  position: relative;
  .tg-blog {
    position: absolute;
    top: 30%;
  }
  .link-with-icon.right {
    color: $color-quaternary;
    float: right;
    font-size: 13px;
    font-weight: bold;
    &:hover {
      color: $color-primary;
    }
  }
  .blog-list {
    display: grid;
    grid-template-columns: calc(50% - 43px);
    grid-gap: 43px 53px;
    margin-top: 18px;
    width: 100%;
    .item {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      &:first-child {
        grid-column: 1;
        grid-row: 1 / span 2;
        figure {
          width: 100%;
          &:after {
            background: -moz-linear-gradient(
              top,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.59) 100%
            );
            background: -webkit-linear-gradient(
              top,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.59) 100%
            );
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.59) 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#96000000',GradientType=0 );
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0.58;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .text {
          bottom: 0;
          left: 0;
          padding: 40px;
          position: absolute;
          right: 0;
          width: 100%;
          .tag {
            background: $color-quaternary;
            border-radius: 6px;
            color: $color-initial;
            display: inline-block;
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 15px;
            padding: 5px 10px;
            &.product,
            &.red {
              background: $color-primary;
            }
            &.tips,
            &.blue {
              background: $color-secondary;
            }
          }
          .date {
            color: $color-initial;
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            line-height: 15px;
            float: right;
            margin-bottom: 0;
            position: relative;
            top: 11px;
          }
          h4 {
            color: $color-initial;
            font-size: 19px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 10px;
          }
          p {
            color: $color-initial;
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 0;
          }
        }
      }
      &:nth-child(2) {
        grid-column: 2 / span 1;
        grid-row: 1;
      }
      &:nth-child(3) {
        grid-column: 2 / span 1;
        grid-row: 2;
      }
      &:hover {
        figure {
          img {
            @include transform(scale(1.02));
            transition: 0.3s all ease;
          }
          &:before {
            animation: shine 0.75s;
            -webkit-animation: shine 0.75s;
          }
        }
      }
      figure {
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        width: 250px;
        &:before {
          background: -webkit-linear-gradient(
            left,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 100%
          );
          content: "";
          display: block;
          left: -75%;
          position: absolute;
          top: 0;
          transform: skewX(-25deg);
          -webkit-transform: skewX(-25deg);
          height: 100%;
          width: 50%;
          z-index: 2;
        }
        img {
          display: block;
          height: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
          transition: 0.3s all ease;
        }
        .tag {
          background: $color-quaternary;
          border-radius: 6px;
          color: $color-initial;
          display: inline-block;
          font-size: 13px;
          font-weight: bold;
          padding: 5px 10px;
          position: absolute;
          left: 20px;
          bottom: 15px;
          &.product,
          &.red {
            background: $color-primary;
          }
          &.tips,
          &.blue {
            background: $color-secondary;
          }
        }
      }
      .text {
        padding: 8px 30px 8px 30px;
        width: calc(100% - 250px);
        .date {
          display: block;
          color: $color-quaternary;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          margin-bottom: 9px;
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 9px;
        }
        a {
          color: $color-quaternary;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          text-transform: uppercase;
          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
}
.contact-me {
  position: relative;
  .tg-contact {
    position: absolute;
    top: 30%;
  }
  > img {
    height: auto;
    object-fit: cover;
    object-position: center;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .text {
    left: 45%;
    position: absolute;
    top: 181px;
    width: 480px;
    z-index: 2;
    h3 {
      color: $color-initial;
      font-size: 23px;
      font-weight: 500;
      margin-bottom: 14px;
    }
    p {
      color: $color-initial;
      font-size: 30px;
      font-weight: bold;
      line-height: 40px;
    }
  }
  form {
    background: $color-initial;
    border: 1px solid #dedede;
    border-radius: 20px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: -130px 0 0;
    overflow: hidden;
    padding: 31px 58px;
    position: relative;
    z-index: 2;
    .input-field {
      width: calc(100% / 5);
      padding-right: 40px;
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
      label {
        color: $color-quaternary;
        font-size: 13px;
        font-weight: bold;
        padding-left: 2px;
      }
      select {
        padding: 0 10px;
      }
      select,
      input {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 7px;
        padding-left: 0;
      }
      .note {
        color: $color-quinary;
        display: inline-block;
        font-size: 13px;
      }
      button {
        margin: 0;
      }
    }
  }
}
.testimonial {
  padding: 50px 0 0;
  position: relative;
  .tg-testimonial {
    position: absolute;
    top: 30%;
  }
  .title-section {
    margin-bottom: 22px;
  }
  .testimonial-slider {
    background: #f2f2f2;
    border-radius: 20px;
    padding: 54px 54px 78px;
    .item {
      margin: 0 16px;
      width: calc(50% - 32px);
      h4 {
        font-size: 19px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 10px;
        position: relative;
        &:before {
          color: $color-secondary;
          content: '"';
          font-size: 24px;
          font-weight: bold;
          line-height: 30px;
          left: -17px;
          position: absolute;
          top: -7px;
        }
      }
      h5 {
        color: $color-secondary;
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      p {
        font-size: 15px;
        line-height: 30px;
        margin-bottom: 15px;
        position: relative;
        &:after {
          color: $color-secondary;
          content: '"';
          font-size: 24px;
          font-weight: bold;
          line-height: 30px;
          position: relative;
          right: -15px;
          top: 17px;
        }
      }
    }
    .slick-arrow {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 40px;
      z-index: 10;
      @include transform(translateY(-50%));
      &.slick-disabled {
        display: none !important;
      }
      &:hover svg {
        g {
          opacity: 1;
        }
        circle {
          fill: $color-secondary;
          transition: 0.05s all ease;
        }
        path {
          fill: $color-initial;
          opacity: 1;
          transition: 0.05s all ease;
        }
      }
      svg circle,
      svg path {
        transition: 0.05s all ease;
      }
      &.arrow-prev {
        left: -17px;
      }
      &.arrow-next {
        right: -17px;
      }
    }
  }
}
