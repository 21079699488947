.step-transaction {
  .list {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 2.6rem 2.6rem 2.6rem 9rem;
    border-bottom: 0.1rem solid rgba(229, 229, 229, 0.1);
    position: relative;
    z-index: 1;

    h6,
    h5 {
      margin-bottom: 0;
    }
    h6 {
      font-size: 1.4rem;
    }
    h5 {
      font-weight: 700;
      font-size: 1.6rem;
      color: rgba(255, 255, 255, 0.5);
    }
    .num-step {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: #39337e;
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.3rem;
      font-weight: 700;
      text-align: center;
      line-height: 4rem;
      position: relative;
      position: absolute;
      top: 3rem;
      left: 3rem;

      &:before {
        display: none;
        content: "";
        margin: auto;
        width: 1rem;
        height: 1rem;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }

    &.active,
    &.done {
      h5 {
        color: $color-initial;
      }
      .num-step {
        background: $color-initial;
        color: #39337e;

        &:before {
          transform: scale(5.5);
        }
      }
    }
  }
}

.triger-show-floating {
  display: none;
}
.summary {
  padding: 5rem 3.3rem;

  h5 {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-initial;
    margin-bottom: 1rem;
  }
  .row {
    align-items: center;
    .column {
      margin-bottom: 1.5rem;
      h5 {
        line-height: 1.3;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
    .side-val {
      text-align: right;
    }
  }
}

.need-help,
.need-help-mobile {
  padding: 5rem 3.3rem;
  background: #1b1464;
  border-radius: 0 0 1rem 1rem;

  h5 {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-initial;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.3rem;
  }
}
.need-help-mobile {
  display: none;
}
.total-mobile {
  display: none;
}

.title-dash {
  margin: 0 -3rem 1rem;
  h4 {
    color: #1b1464;
    display: inline-block;
  }
  button {
    float: right;
    position: relative;
    top: -4px;
  }
}

.box-checkbox-box {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  .checkbox-box {
    position: relative;
    display: inline-block;
    margin-right: 3rem;
    vertical-align: top;
    span {
      display: block;
      flex-basis: 100%;
      max-width: 100%;
    }
    input {
      width: 100%;
      height: 100%;
      opacity: 0;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;

      &:checked + .inner-checkbox {
        background: #39337e;
        border: 0.1rem solid #39337e;
        color: $color-initial;
      }
    }
    .inner-checkbox {
      align-content: center;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      width: 15rem;
      height: 15rem;
      border: 0.1rem dashed #b2b0d5;
      border-radius: 1rem;
      background: #eae9f5;
      color: #b2b0d5;
      font-size: 1.6rem;
      text-align: center;
    }
    &.check-white {
      margin-right: 20px;
      margin-bottom: 20px;
      input {
        &:checked + .inner-checkbox {
          border: 1px dashed rgba(67, 60, 155, 0.4);
          background: #433c9b;
          box-shadow: -8px 16px 32px 0px rgba(67, 60, 155, 0.3);
          .number {
            color: $color-initial;
          }
          span {
            color: $color-initial;
          }
        }
      }
      .inner-checkbox {
        background: #fff;
        width: 135px;
        height: 135px;
        .number {
          color: #433c9b;
          font-weight: 500;
          font-style: normal;
          font-size: 24px;
        }
        span {
          color: #433c9b;
          font-weight: 500;
          font-size: 15px;
          line-height: 1;
        }
      }
    }
    &.check-package {
      margin-right: 20px;
      margin-bottom: 20px;
      input {
        &:checked + .inner-checkbox {
          background: $color-initial;
          color: #494949;
          border: 2px solid #433c9b;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

          span {
          }
          .button {
            background: #c7c7c7;
            border-color: #c7c7c7;
            cursor: default;
            span {
              &.select {
                display: none;
              }
              &.cancel {
                display: flex;
              }
            }
          }
        }
      }
      .inner-checkbox {
        width: 186px;
        height: 373px;
        background: $color-initial;
        align-content: flex-start;
        padding: 16px 0 0 0;
        display: block;
        color: #494949;
        span {
          color: #433c9b;
          font-weight: 700;
          font-size: 15px;
          line-height: 1;
          margin-top: 8px;
          &.black {
            color: #000;
          }
        }
        .text-bottom {
          margin: 20px 1px 0;
          padding: 20px 13px;
          background: linear-gradient(180deg, #f4f4f4 0%, #fff 100%);
          h6 {
            color: #333;
            font-weight: 700;
            margin-bottom: 12px;
          }
          ul {
            text-align: left;
            li {
              font-size: 12px;
              position: relative;
              padding: 0 0 0 20px;
              margin: 0;
              &::before {
                position: absolute;
                width: 16px;
                height: 16px;
                content: "";
                background: url("../images/material/cheklist.svg") no-repeat 0 0;
                top: 2px;
                left: 0;
              }
            }
          }
          .button {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 17px;
            min-width: 50px;
            width: min-content;
            padding: 0 16px;
            font-size: 12px;
            span {
              color: #fff;
              font-size: 12px;
              font-weight: 700;
              margin: 0;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              &.select {
              }
              &.cancel {
                display: none;
              }
            }
          }
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.package-slider {
    display: block;
    width: calc(100% + 20px);
    margin-left: -10px;
    .slick-track{
      display: flex !important;
    }
    .slick-slide{
      height: inherit !important;
    }
    .inner-checkbox{
      height: 100% !important;
      .text-bottom{
        padding-bottom: 50px !important;
      }
    }
    .checkbox-box {
      margin-bottom: 0;
      &.check-package {
        width: 33.3333%;
        padding: 0 10px;
        margin-right: 0;
        .inner-checkbox {
          width: 100%;
          img {
            display: inline;
          }
        }
      }
    }
    .slick-dots {
      margin: 10px 0 0;
      display: block;
      text-align: center;
      li { display: inline-block; margin-bottom: 0; margin: 0 .5rem; padding: 0; width: auto;
        &.slick-active {
          button { opacity: 1; background: #E21F26; }
        }
        button { background: #443C9B; border: transparent; border-radius: 50%; display: block; height: 10px; line-height: unset; margin-bottom: 0; min-width: auto; opacity: .5; padding: 0; text-indent: -99999px; width: 10px; }
        &:only-child { display: none; }
      }
    }
  }
}
.inner-tab {
  margin-top: 2rem;
}
.box-soft-orange {
  background: #fefaf7;
  border: 1px solid #f4eae2;
  border-radius: 12px;
  padding: 2.2rem 2rem;
  border-radius: 1rem;
  font-size: 1.3rem;
  color: #555555;

  p {
    font-size: 1.3rem;
  }
  table {
    margin-bottom: 0;
    width: 100%;

    th,
    td {
      font-weight: 500;
      font-size: 1.5rem;
      color: #555555;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:first-child {
        font-size: 1.3rem;
        color: #a1a1a1;
        font-weight: 400;
      }
    }
  }

  &.goal {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    table {
      margin-right: 20px;
      width: calc(100% - 160px);
    }
    .link-arrow {
      margin-bottom: 0.5rem;
      width: 140px;
    }
  }
}

.promo-box {
  box-shadow: 0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;

  label {
    font-weight: 400;
  }
  &.mb-3 {
    margin-bottom: 3rem;
  }
}

.box-text-grey {
  display: flex;
  position: relative;
  input[type="text"] {
    background: #f4f4f6;
    border: none;
    height: 5rem;
    width: 200px;
    border-radius: 1rem 0 0 1rem;
    font-size: 1.5rem;
    padding: 0 11rem 0 2rem;
    margin-bottom: 0;
  }
  button {
    border-radius: 0 1rem 1rem 0;
    background: #39337e;
    color: $color-initial;
    border: none;
    height: 5rem;
    width: 14rem;
    padding: 0 1rem;
    text-transform: none;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.center-align {
  text-align: center;
}

.content-tab {
  .list-tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

.selected-box {
  margin-bottom: 1rem;

  a {
    display: inline-block;
    position: relative;
    background: #eae9f5;
    border-radius: 0.5rem;
    color: #39337e;
    padding: 0 4rem 0 2rem;
    line-height: 4rem;

    .close {
      width: 2rem;
      position: absolute;
      top: 0;
      right: 1rem;
      bottom: 0;

      &:before,
      &:after {
        content: "";
        width: 1rem;
        height: 0.2rem;
        background: #39337e;
        margin: -0.2rem auto 0;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 0;
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.notable {
  table {
    th,
    td {
      border: none;
    }
  }
}

.box-asuransi-list {
  .list-asuransi {
    border-radius: 1rem;
    border: 0.1rem solid #e5e5e5;
    margin-bottom: 2rem;
    position: relative;
    cursor: pointer;

    .title-asuransi {
      border-bottom: 0.1rem solid #e5e5e5;
      display: flex;

      h4 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0;
      }
      table {
        margin-right: 1rem;
        margin-bottom: 0;

        tr {
          th,
          td {
            border: none;
            font-size: 1.5rem;
            font-weight: 500;
            padding: 0 1rem;
          }

          &:first-child {
            th,
            td {
              color: #a1a1a1;
              font-size: 1.3rem;
              text-transform: uppercase;
            }
          }
        }
      }
      .button {
        margin-bottom: 0;
      }
      .tl-left {
        border-right: 0.1rem solid #e5e5e5;
        padding: 2.5rem 2rem 2.5rem 3.4rem;
        width: 150px;
      }
      .tl-right {
        padding: 3rem 2rem;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% - 150px);
        table {
          margin-right: 10px;
          width: calc(100% - 120px);
        }
        .button {
          width: 110px;
        }
      }
    }

    .content-asuransi {
      padding: 2.5rem 3.4rem;
      color: #555;

      table {
        width: 100%;
        margin-bottom: 1rem;

        th,
        td {
          font-size: 1.5rem;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
        }
      }
    }
    .detail-popup {
      display: none;
    }
    &.selected {
      border: 0.1rem solid #39337e;
      background: #f5f4ff;
    }
    input[type="radio"] {
      visibility: hidden;
      opacity: 0;
      position: absolute;
    }
  }
}

.box-leisure {
  .list-leisure {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2.8rem;
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid #e5e5e5;
    font-size: 1.5rem;

    h5 {
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 3rem;
    }

    .right-lei {
      flex: 1 1 20%;
      text-align: right;
    }
  }
}

.box-rounded-grey {
  border: 0.1rem solid #e6e6e6;
  border-radius: 1rem;
  padding: 3rem 3rem 0;
  margin: 0 -3rem 3rem;
  h4 {
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
  .title-rounded-grey {
    background: #1b1464;
    color: $color-initial;
    border-radius: 1rem 1rem 0 0;
    margin: -3rem -3rem 0;
    padding: 1.8rem 3.2rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      font-size: 1.3rem;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  .top-line-rounded {
    display: flex;
    margin: -3rem -3rem 3rem;
    padding: 0 3rem;
    border-bottom: 0.1rem solid #e6e6e6;
    h4 {
      font-weight: 500;
      margin-bottom: 0.5rem;
      font-size: 2rem;
    }
    h6 {
      margin-bottom: 1rem;
    }
    .left-box,
    .right-box {
      padding-top: 3rem;
      padding-bottom: 2rem;
    }
    .left-box {
      border-right: 0.1rem solid #e6e6e6;
      flex: 1 0 50%;
      max-width: 50%;
      padding-right: 3rem;
      label {
        font-size: 1.3rem;
        color: #a1a1a1;
        margin-bottom: 0;
      }
      h5 {
        font-size: 1.5rem;
        font-weight: 500;
        color: #555555;
        margin-bottom: 1rem;
      }
    }
    .right-box {
      flex: 1 0 50%;
      max-width: 50%;
      padding-left: 3rem;
      label {
        font-size: 1.3rem;
        color: #a1a1a1;
        margin-bottom: 0;
      }
      h5 {
        font-size: 1.5rem;
        font-weight: 500;
        color: #555555;
        margin-bottom: 1rem;
      }
      button,
      .button {
        font-size: 14px;
        padding: 0 10px;
        width: 100%;
      }
    }
  }

  .data-peserta-polis {
    border: none;
  }
  &.box-accordion {
    display: flex;
    flex-direction: column;
    .button-accordion {
      order: 1;
      &:before {
        right: 1.3rem;
      }
      .box-button-accordion {
        display: flex;
        gap: 10px;
        padding-right: 20px;
      }
      .delete-child,
      .save-child,
      .select-polis {
        display: flex;
        align-items: center;
        color: #fff;
      }
      .icon-select {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        &:before {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 0;
          background: url("../images/material/ico-select.svg") no-repeat;
        }
      }
      .delete-child {
        .icon-delete {
          position: relative;
          width: 20px;
          height: 20px;
          &:before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
            background: url("../images/material/icon-delete.svg") no-repeat;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .save-child {
        display: flex;
        align-items: center;
        color: #fff;
        .icon-save {
          position: relative;
          width: 20px;
          height: 20px;
          &:before {
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
            background: url("../images/material/icon-save.svg") no-repeat center;
            background-size: 16px;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .content-accordion {
      order: 3;
    }
    .box-rounded-dashed-grey {
      order: 2;
      margin-bottom: 0;
      .checkbox-form {
        cursor: pointer;
        font-size: 1.3rem;
        padding-left: 3rem;
        input[type="checkbox"] {
          top: 0.1rem;
          cursor: pointer;
        }
      }
    }
    &:hover {
      .button-accordion {
        background: #1b1464;
      }
    }
    &.active {
      .button-accordion {
        background: #1b1464;
      }
    }
  }
}

.box-total-asuransi {
  border: 0.1rem solid #e6e6e6;
  border-radius: 1rem;
  margin: 0 -3rem 3rem;

  label {
    color: #a1a1a1;
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  h5 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #555555;
    margin-bottom: 1.5rem;
  }
  table {
    margin: 0;
    th,
    td {
      font-size: 1.5rem;
      font-weight: 500;
      padding: 0 5px 1rem;
      text-align: right;
      width: 50%;

      &:first-child {
        font-size: 1.3rem;
        font-weight: 400;
        color: #a1a1a1;
        text-align: left;
      }
    }
  }

  .top-total-asuransi {
    display: flex;
    .left-total {
      padding: 3rem;
      border-right: 0.1rem dashed #e5e5e5;
      flex: 1 0 50%;
      max-width: 50%;
    }
    .right-total {
      padding: 3rem 2.5rem;
      width: 100%;
    }
  }
  .bottom-total-asuransi {
    background: #39337e;
    color: $color-initial;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 0 0 1rem 1rem;
    padding: 1.5rem 2.5rem 1.5rem 58%;
    display: flex;
    justify-content: space-between;

    label,
    h4 {
      font-size: 1.8rem;
      font-weight: 700;
      color: $color-initial;
      margin: 0;
    }
  }
}

.tab-rounded {
  .btn-tab-rounded {
    a {
      display: inline-block;
      padding: 1rem 2.2rem;
      border-radius: 1rem;
      border: 0.1rem solid #443c9b;
      font-size: 1.5rem;
      font-weight: 500;
      color: #443c9b;
      margin-right: 1rem;

      &.active {
        background: #443c9b;
        color: $color-initial;
      }
    }
  }
  .content-tab-rounded {
    .list-tab {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.box-rounded-dashed-grey {
  border: 0.1rem dashed #e8e8e8;
  border-radius: 1rem;
  padding: 2.2rem 3rem;
  margin: 0 -3rem 3rem;
}

.title-icon {
  position: relative;
  margin-bottom: 5rem;

  img {
    margin-right: 2rem;
  }
  h2 {
    font-weight: 700;
    color: #1b1464;
    display: inline-block;
  }
}

.label-rounded {
  display: inline-block;
  padding: 0.3rem 1.5rem;
  border-radius: 0.8rem;
  background: #39337e;
  color: $color-initial;
  font-size: 1.2rem;
}

.box-paket {
  .list-paket {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    border-bottom: 0.1rem dashed #e5e5e5;
    margin: 0 -3rem 3rem;
    padding: 0 3rem 3rem;

    h5,
    b {
      font-weight: 500;
      font-size: 1.5rem;
    }
    label {
      font-weight: 400;
      color: #a1a1a1;
      font-size: 1.3rem;
      margin-bottom: 0;
    }
  }
  .subtotal-paket,
  .total-paket {
    margin: 0 -3rem;
  }
  .subtotal-paket {
    padding: 0 3rem 2rem 63%;

    table {
      margin-bottom: 0;
      width: 100%;

      th,
      td {
        font-weight: 500;
        font-size: 1.5rem;
        color: #555555;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        text-align: right;

        &:first-child {
          font-size: 1.3rem;
          color: #a1a1a1;
          font-weight: 400;
          text-align: left;
        }
      }
    }
  }
  .total-paket {
    margin-bottom: -3rem;
    background: #39337e;
    color: $color-initial;
    border-radius: 0 0 1rem 1rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem 1rem 63%;

    label,
    h5 {
      margin-bottom: 0;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  &.box-paket2 {
    .box-paket2-inner {
      margin-bottom: 24px;
      .list-paket {
        div {
          &.logo {
            width: 77px;
            margin-right: 80px;
          }
          &:last-child {
            padding-right: 50px;
          }
        }
      }
    }
  }
}

.box-data-list {
  margin-bottom: 3rem;
  h5,
  b {
    font-weight: 500;
    font-size: 1.5rem;
  }
  label {
    font-weight: 400;
    color: #a1a1a1;
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}
.review {
  @include afterclear;
  textarea {
    height: 120px !important;
  }
}
.rates {
  float: left;
  height: 46px;
  margin-bottom: 37px;
  margin-top: -20px;
  padding: 0;
  position: relative;
  @include afterclear;
  &:not(:checked) > input {
    position: absolute;
    top: 0;
    visibility: hidden;
  }
  &:not(:checked) > label {
    color: #ccc;
    cursor: pointer;
    float: right;
    margin-right: 10px;
    font-size: 30px;
    overflow: hidden;
    white-space: nowrap;
    svg {
      stroke-width: 1px;
    }
  }
  > input:disabled > label {
    cursor: default;
  }
  svg {
    height: 25px;
    width: 25px;
    /*path { stroke: red; }*/
  }
  > input:checked ~ label > svg {
    path {
      fill: red;
      stroke: red;
      stroke-width: 1px;
    }
  }
  /*&:hover:not(:checked) > label:hover,
    &:hover:not(:checked) > label:hover ~ label > svg {
        .cls-1{fill:$yellow;stroke:$yellow}
    }*/
}

.review-text {
  text-align: center;
  h4 {
    color: #111111;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 33px;
  }
  img {
    margin: 0 auto 33px;
  }
  p {
    color: #999999;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto 18px;
    width: 70%;
  }
}
.text-center {
  text-align: center;
}

.wrap-button {
  margin-top: 30px;
}
.add-item {
  margin: 0 -3rem 3rem;
  text-align: right;
}

.overflow-popup {
  .form2 {
    .form-group {
      margin-bottom: 0;
    }
  }
  .notable {
    display: none;
  }
}

.box-white {
  background-color: white;
  padding: 60px 47px;
  box-sizing: border-box;
  border-radius: 12px;
  .title-ty {
    display: flex;
    justify-content: space-between;
    h5 {
      font-weight: 500;
    }
  }
  .desc-bank {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #e8e8e8;
    figure {
      display: flex;
      align-items: center;
    }
    .desc-bank--text {
      margin-left: 20px;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
      &.with-btn {
        align-items: center;
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        flex-direction: row;
        line-height: normal;
        gap: 24px;
        margin-left: 0;
      }
      small {
        font-weight: 500;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
    .total {
      margin-left: auto;
      font-size: 28px;
      font-weight: 500;
    }
  }
  .wrap-barcode {
    border-bottom: 1px solid #e8e8e8;
    margin: 37px auto;
    padding-bottom: 34px;
    text-align: center;
    h5 {
      color: #272727;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 14px;
    }
    figure {
      display: block;
      img {
        display: block;
        margin: 0 auto;
      }
    }
    span {
      color: #272727;
      display: block;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .wrap-time {
    text-align: center;
    margin: 40px 0px;
    h5 {
      margin-bottom: 0;
      font-weight: 400;
    }
    time {
      margin: 20px 0;
      display: block;
      span {
        font-size: 32px;
        font-weight: 500;
        color: black;
      }
    }
  }
  .box-accordion {
    .button-accordion {
      background-color: transparent;
      border-radius: 8px;
    }
    .content-accordion {
      border-top: none;
      padding-top: 0;
    }
  }
  .button {
    display: block;
    height: 60px;
    line-height: 60px;
    background-color: #433c9b;
    margin-top: 40px;
  }
}

.copy-code {
  cursor: pointer;
  img {
    display: block;
  }
  &[data-notify] {
    position: relative;
    &:before {
      background: #222;
      border-radius: 5px;
      color: #ffff;
      content: attr(data-notify);
      font-size: 12px;
      left: 130%;
      padding: 4px 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #222;
      content: "";
      height: auto;
      right: -8px;
      width: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
