@media (max-width: 767px) {
  .promotion {
    &.page .promotion-list {
      margin: 0;
      padding: 36px 0;
      &.blog {
        margin: 0;
        padding: 36px 0;
        .item {
          margin: 0 0 36px;
          width: 100%;
          figure {
            margin-bottom: 20px;
            .tag {
              bottom: 0;
              font-size: 13px;
              margin: 20px;
            }
          }
          .date {
            font-size: 14px;
          }
          h4 {
            margin-bottom: 0;
          }
          p {
            display: none;
          }
        }
      }
      .item {
        margin: 0 0 36px;
        width: 100%;
        figure {
          margin-bottom: 20px;
        }
      }
    }
    &.other {
      h4 {
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 40px;
      }
      .promotion-list.blog {
        padding: 0;
      }
    }
  }
  .promo-detail {
    padding-top: 26px;
    .promo-ticket {
      flex-wrap: wrap;
      .img {
        width: 100%;
      }
      .text {
        text-align: center;
        padding: 0;
        width: 100%;
        h4 {
          border-bottom: 1px solid rgba(236, 236, 236, 0.87);
          padding: 26px 40px 20px;
        }
        table {
          margin-bottom: 0;
          tr {
            display: block;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            td {
              display: block;
              text-align: center;
              padding: 0 20px;
            }
          }
        }
        .button {
          margin: 20px 20px 22px;
          width: calc(100% - 40px);
        }
      }
    }
    .promo-terms {
      padding: 38px 20px;
    }
  }
  .blog-detail {
    .share {
      top: 85px;
      position: static;
      li {
        display: inline;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        margin-right: 6px;
        &:first-child {
          position: relative;
          top: -5px;
        }
        img {
          height: 19px;
          width: auto;
        }
      }
    }
    .wrapper {
      padding: 0 10px;
    }
    .tags .date {
      font-size: 14px;
      line-height: 24px;
    }
    .title {
      font-size: 19px;
      line-height: 28px;
      margin-bottom: 31px;
      padding: 0 0 32px;
    }
  }
}
