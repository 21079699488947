@media (max-width: 1480px) {
	header nav .menu > li.nav-language { margin-left: 85px; }
}

@media (min-width: 1024px) and (max-height: 580px) {
	header {
		nav {
			.menu {
				> li {
					&.dropdown {
						ul {
							max-height: 420px;
							overflow: auto;
							border-radius: 10px;
							padding-top: 0;
							margin-top: 19px;
							&::before {
								content: unset;
							}
						}
						&:hover {
							&::before {
								content: "";
								border-left: 7px solid transparent;
								border-right: 7px solid transparent;
								border-bottom: 7px solid #fff;
								border-radius: 2px;
								height: 0;
								left: 0;
								margin: 0 auto;
								position: absolute;
								right: 0;
								bottom: -20px;
								width: 0;
							}
						}
					}
				}
			}
		}
	}
}