@media (max-width: 767px) {
	.page-404 { padding-top: 29px;
		.img { margin-bottom: 34px; width: 164px; }
		.title { font-size: 17px; line-height: 30px; margin-bottom: 20px; padding-bottom: 24px; }
	}
	.underconstruction { flex-wrap: wrap;
		figure { width: 100%; 
			img { height: auto; width: 100%; }
		}
		.text{ margin: 0 auto 30px; width: 85%; 
			> img { margin-bottom: 10px; }
			.title { font-size: 17px; line-height: 30px; margin-bottom: 26px; padding-bottom: 24px; }
			p { line-height: 24px; }
		}
	}
	.search-result .search-list .item {
		h4 { font-size: 16px; line-height: 30px; margin-bottom: 19px; }
		p { font-size: 14px; line-height: 24px; }
	}
	.ty-page { margin-top: 30px;
		.list-- {
			.column.column-33 { flex: 0 0 100%; max-width: 100%; }
		}
	}
}