@media (max-width: 1279px){
	.search-box form {
		input { font-size: 19px; height: 70px; line-height: 30px; padding: 24px 30px 24px 30px; }
		button { height: 70px; padding: 0 30px; }
	}
	.help { position: relative;
		.wrapper { position: unset; }
		.overlay { position: absolute; }
		.wrapper { flex-wrap: wrap; }
		aside { margin-bottom: 75px; padding-right: 0; width: 100%; 
			> span { border-bottom: 1px solid #E2E2E2; color: #555555; display: block; font-size: 23px; font-weight: bold; line-height: 34px; margin-bottom: 20px; padding-bottom: 20px; position: relative;
				&:after { content: url(../images/material/ic-chevron-top.svg); position: absolute; right: 20px; top: 5px; @include transform(rotate(180deg)); }
				&.active {
					&:after { content: url(../images/material/ic-chevron-top.svg); top: -5px; @include transform(rotate(0)); }
				}
			}
			> ul { background: $color-initial; border-radius: 0px 0px 20px 20px; display: none; left: 0; padding: 0 5% 30px !important; position: absolute; right: 0; top: 80px; z-index: 99; }
		}
		.tab-content { width: 100%; 
			.std-content {
				.title { font-size: 19px; line-height: 30px; 
					&:before { width: 50px; }
				}
				.text h5 { font-weight: normal; }
			}
		}
	}
}
@media (max-width: 991px) {
	.search-box form { margin-bottom: 36px; }
	.help { padding: 0 0 57px; 
		aside { margin-bottom: 0; }
	}
}