.content-with-banner {
  margin-top: -4rem;

  .wrapper {
    background: $color-initial;
    z-index: 2;
    border-radius: 2rem 2rem 0 0;
    padding-top: 6.7rem;
  }
}

.side-line {
  position: relative;
  padding-left: 5.4rem;

  &:before {
    content: "";
    width: 0.2rem;
    height: 6.5rem;
    background: #d7001b;
    position: absolute;
    top: 0;
    left: 0;
  }
  h4 {
    font-weight: 400;
  }
  p {
    line-height: 1.6;
  }
}

.box-accordion {
  border: 0.1rem solid #e2e2e2;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
  &.blue {
    margin: 0 -3rem 3rem;
    &:hover > .button-accordion {
      background: $color-secondary;
    }
    &.active > .button-accordion,
    > .button-accordion {
      background: $color-secondary;
      color: $color-initial;
      &:before {
        background: url(../images/material/ic-chevron-bottom-white.svg)
          no-repeat center;
      }
    }
  }
  .button-accordion {
    padding: 2rem 5.2rem 2rem 3.2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      width: 2rem;
      background: url("../images/material/icon-arrow-red.svg") no-repeat center;
      position: absolute;
      top: 0;
      right: 2.7rem;
      bottom: 0;
    }
    span {
      display: block;
      color: #a0a0a0;
      font-size: 1.3rem;
    }
    h5 {
      margin-bottom: 0;
    }
    h4 {
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .content-accordion {
    border-top: 0.1rem solid #e2e2e2;
    border-radius: 0 0 0.5rem 0.5rem;
    display: none;
    padding: 3.5rem;
    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .box-accordion {
      .button-accordion {
        padding: 1rem 5.2rem 1rem 2.2rem;
        &:before {
          right: 2rem;
        }
        h4 {
          color: #333;
          font-size: 18px;
          font-weight: normal;
        }
      }
      .content-accordion {
        padding: 1.5rem 1rem;
        table tr {
          &:nth-child(odd) {
            background: #f5f5f5;
          }
          td {
            padding: 5px 10px;
          }
        }
        .button {
          margin-bottom: 0;
        }
      }
    }
    &.snk {
      max-height: 508px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(36, 34, 119, 0.4);
      }
      /*&::-webkit-scrollbar-thumb:hover { background: #555; }*/
    }
  }

  &:hover,
  &.active {
    .button-accordion {
      background: #f4f4f6;
    }
  }
  &.active {
    .button-accordion {
      &:before {
        @include transform(rotate(180deg));
      }
    }
    .content-accordion {
      display: block;
    }
  }
}
.text-right {
  text-align: right;
}
