@media (max-width: 1279px){
	.section-account {
		.box-aside {
			aside { margin-bottom: 100px; overflow: unset; position: relative; top: unset;
				.account-aside { cursor: pointer; padding: 2rem 4rem; position: relative; 
					&:after { content: url(../images/material/ic-chevron-bottom-white.svg); position: absolute; right: 40px; top: 50%; @include transform(translateY(-50%)); }
					figcaption { padding: .8rem 0; }
				}
				.nav-aside { background: #1B1464; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; left: 0; position: absolute; right: 0; top: 100%; z-index: 9; 
					> li { display: none; padding: 2.1rem 2.3rem 2.1rem 5.8rem;
						&.active { border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; display: block; }
						&.active.parent ul { display: none; }
					}
					&.active > li.active.parent ul { display: block; }
				}
			}
			/*article input[type='submit'] { min-width: 190px; width: auto; }*/
			.form-group.row { flex-wrap: wrap;
				&.row-center {
					.column.column-50 { flex: 0 0 100%; margin-bottom: 20px; max-width: 100%; 
						.button { width: 100%; }
					}
				}
			}
		}
	}
	.box-polis-perjalanan {
		h5 { font-size: 19px; line-height: 30px; }
		span { font-size: 13px; line-height: 18px; }
	}
	.tab-polis .list-content-tab {
		> img { display: block; width: 100%; }
		h4 { font-size: 19px; line-height: 30px; }
	}
	.body-polis-perjalanan {
		.button, button { width: 100%; }
	}
	.box-list-info .list-info {
		h6 { font-size: 1.5rem; }
		span { font-size: 1.3rem; }
	}
	.box-polis-perjalanan {
		.title-dash { font-size: 2rem; padding: 20px 40px; }
		.titles { padding: 0 40px; }
		.berlaku-polis form { padding: 0 40px; }
	}
}
@media (max-width: 991px) {
	.box-polis-perjalanan a.link-with-icon { text-transform: uppercase; }
	.tab-polis {
		.btn-tab { padding: 2.4rem 3em 0; }
		.list-content-tab { padding: 3rem 4.2rem; }
	}
	.btn-delete:before { margin-right: 10px; }
}