@media (max-width: 767px) {
  form.form2 .form-group label {
    font-size: 1.4rem;
    line-height: 20px;
  }
  form .side-label-text {
    align-items: center;
    margin-bottom: 16px;
    label {
      margin-bottom: 0 !important;
      width: 100%;
      white-space: normal;
    }
    input[type="text"] {
      padding: 1rem;
      text-align: center;
    }
  }
  form {
    .form-group {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      select {
        padding-bottom: 10px;
      }
      input[type="button"],
      input[type="submit"],
      .button {
        width: 100%;
      }
      select {
        padding-left: 0;
      }
    }
    .btn-group .btn {
      width: 100%;
      .button,
      button,
      input[type="submit"] {
        width: 100%;
      }
    }
    .text-label {
      span {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  form .form-group.light-margin {
    margin-bottom: 2rem;
  }
  .contact-us {
    margin-top: -30px;
    .wrapper {
      flex-wrap: wrap;
    }
    .maps {
      width: 100%;
      iframe {
        height: 224px;
      }
      .address {
        padding: 32px 34px 77px;
      }
    }
    .contact-form {
      padding: 40px 20px;
      width: 100%;
    }
  }
}
