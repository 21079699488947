@media (max-width: 767px) {
  header {
    nav {
      margin-right: 35px;
      .menu > li {
        > a {
          padding: 28px 0 52px;
        }
        &.nav-profile {
          margin-left: 0;
          > a {
            padding: 29px 0 50px;
            &.login span {
              display: none;
            }
          }
          span {
            display: none;
          }
          .has-login {
            left: 42%;
            .profile {
              padding: 27px 30px;
            }
          }
        }
        &.nav-search {
          margin-left: 0;
          padding: 0 7px;
          position: unset;
          img {
            padding: 33px 0 27px;
          }
          .nav-search-box {
            height: 55px;
            right: 0;
            top: calc(100% + 8px);
            width: 100%;
            &:before {
              opacity: 0;
            }
            form {
              margin-bottom: 0;
              input[type="text"] {
                font-size: 14px;
                margin-bottom: 0;
                padding: 0 90px 0 15px;
              }
              input[type="submit"] {
                border: 1px solid $color-secondary;
                font-size: 13px;
                width: 90px;
              }
            }
          }
        }
        &.nav-cart {
          margin-left: 5px;
        }
      }
    }
    .menu-mobile {
      height: 82vh;
      padding: 24px 10px 24px 20px;
      > ul {
        padding-right: 10px;
        > li {
          &.dropdown:after {
            right: 10px;
            top: 22px;
          }
          &:first-child.dropdown:after {
            top: -1px;
          }
          > a {
            font-size: 16px;
          }
          ul li a {
            font-size: 14px;
          }
        }
      }
    }
    .burger-menu {
      > img {
        width: 25px;
      }
    }
  }
  .header-login {
    padding: 1.2rem 3rem 1.2rem 3rem;
    .right-header p {
      display: none;
    }
  }
  .banner .item {
    figure img {
      height: 420px;
      object-fit: cover;
    }
    .item-text {
      width: 80%;
      > img {
        height: 24px;
        margin-bottom: 5px;
      }
      h1 {
        font-size: 19px;
        line-height: 30px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 23px;
      }
    }
  }
  .banner-small .item {
    figure img {
      height: 200px;
    }
    .item-text {
      margin: 0 auto;
      width: 90%;
      h1 {
        font-size: 19px;
        line-height: 28px;
      }
    }
  }
  .sumary-text {
    margin: -40px auto 34px;
    .wrapper {
      padding: 43px 40px;
    }
    article {
      &:before {
        height: 40px;
        left: -20px;
      }
      .title {
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 34px;
        padding-bottom: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  .std-content {
    h1 {
      font-size: 19px;
      line-height: 28px;
    }
    h2 {
      font-size: 17px;
      line-height: 26px;
    }
    h3 {
      font-size: 16px;
      line-height: 26px;
    }
    h4 {
      font-size: 15px;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 30px;
    }
    .row {
      flex-wrap: wrap;
      .box-half {
        &:first-child {
          order: 2;
          width: 100%;
        }
        &:last-child {
          order: 1;
          margin-bottom: 30px;
          padding: 0;
          width: 100%;
        }
      }
    }
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid #ccc;
      border-bottom: unset;
      &:last-child {
        border-bottom: 1px solid #ccc;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 54%;
      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }
  .title {
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 30px;
    padding-bottom: 24px;
  }
  .paging li {
    &:first-child {
      background: url(../images/material/ic-chevron-left-light.svg) no-repeat
        center;
      height: 40px;
      margin-right: 10px;
      text-indent: -999px;
      width: 40px;
    }
    &:last-child {
      background: url(../images/material/ic-chevron-right-light.svg) no-repeat
        center;
      height: 40px;
      margin-left: 10px;
      text-indent: -999px;
      width: 40px;
    }
    a {
      font-size: 14px;
    }
  }
  .input-file {
    width: 100% !important;
  }
  .note-form {
    display: block;
    margin: 5px 0;
    text-align: center;
  }
  .checkbox-form {
    padding-left: 4rem;
    input[type="checkbox"] {
      height: 2rem;
      background-size: cover;
      top: 4px;
      width: 2rem;
    }
  }
  footer {
    padding: 20px 0 30px;
    .foot-top {
      flex-direction: column;
    }
    .foot-left {
      float: none;
      width: 100%;
      align-items: center;
      .ojk {
        margin-bottom: 10px;
        text-align: center;
      }
      .social-media {
        span {
          text-align: center;
        }
        a {
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    .foot-right {
      width: auto;
      margin-bottom: 20px;
      img {
        max-width: 173px;
        max-height: 130px;
      }
    }
    .foot-bottom .sitemap {
      margin-bottom: 20px;
      padding: 0;
      li {
        margin: 0 10px;
        padding: 0;
        width: auto;
        + li {
          padding-left: 0;
          &:before {
            display: none;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        /*&:nth-child(2n + 1) { margin-right: 5px; padding-left: 0; 
					&:before { display: none; }
				}*/
        a {
          font-size: 13px;
        }
      }
    }
  }
}
