.promotion {
	&.bg-grey { background: #F4F4F6; }
	&.page { padding-top: 0;
		.promotion-list { background: $color-initial; border-bottom: none; border-radius: 20px; display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: wrap; margin: -40px -10px 0; padding: 20px 10px; position: relative; z-index: 9;
			.item { margin: 0 10px 40px; width: calc(100% / 3 - 20px); 
				figure { border-radius: 20px; margin-bottom: 26px; overflow: hidden;
					img { display: block; height: 100%; object-fit: cover; object-position: center; width: 100%; }
				}
				h4 { color: $color-tertiary; font-size: 17px; font-weight: 500; line-height: 24px; margin-bottom: 10px; padding-right: 30px; }
				a:hover h4 { color: $color-secondary; }			}
			&.blog {
				.item { margin-bottom: 60px;
					figure { border-radius: 20px; margin-bottom: 24px; overflow: hidden; position: relative; 
						.tag { background: $color-quaternary; border-radius: 6px; color: $color-initial; display: inline-block; font-size: 13px; font-weight: bold; margin: 10px; padding: 5px 10px; position: absolute; z-index: 2;
							&.product,&.red { background: $color-primary; }
							&.tips,&.blue { background: $color-secondary; }
						}
					}
					.date { color: #9C9C9C; display: block; font-size: 13px; font-weight: bold; line-height: 14px; margin-bottom: 10px; }
					h4 { color: $color-tertiary; font-size: 17px; font-weight: 500; line-height: 24px; margin-bottom: 10px; }
					p { color: $color-quinary; font-size: 15px; line-height: 24px; margin-bottom: 0; }
				}
			}
		}
	}
	&.other { padding: 57px 0 47px;
		h4 { font-size: 23px; font-weight: bold; margin-bottom: 39px; }
		.promotion-list.blog { background: transparent; margin: 0 -10px; padding: 0; 
			.item { background: transparent; margin-bottom: 0; }
		}
	}
}
.promo-detail { padding-top: 82px; 
	.promo-ticket { border-radius: 20px 20px 0 0; display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden;
		.img { width: 396px; 
			img { height: 100%; object-fit: cover; object-position: center; width: 100%; }
		}
		.text { background: $color-secondary; padding: 37px 56px; width: calc(100% - 396px); 
			h4 { color: $color-initial; font-size: 20px; font-weight: 500; line-height: 30px; }
			table {
				tr td { border: none; color: $color-initial; font-size: 13px; padding: 5px 0; }
			}
			.button:hover { border: 1px solid $color-primary; }
		}
	}
	.promo-terms { background: #F4F4F6; border-radius: 0 0 20px 20px; padding: 70px 103px; }
}
.blog-detail { min-height: 600px; padding: 50px 0;
	.wrapper { padding: 0 208px; position: relative; }
	.tags { border-bottom: 1px solid #E2E2E2; margin-bottom: 26px; padding-bottom: 20px; @include afterclear; 
		.tag { background: $color-quaternary; border-radius: 6px; color: $color-initial; display: inline-block; font-size: 13px; font-weight: bold; padding: 5px 10px;
			&.product,&.red { background: $color-primary; }
			&.tips,&.blue { background: $color-secondary; }
		}
		.date { color: #9C9C9C; float: right; font-size: 18px; font-weight: bold; line-height: 21px; margin-top: 8px; }
	}
	.share { position: absolute; left: 110px; top: 330px; 
		li { font-size: 16px; font-weight: bold; line-height: 34px; }
	}
	.title { font-size: 40px; font-weight: bold; line-height: 60px; margin-bottom: 40px; max-width: 100%; padding-bottom: 36px; position: relative; text-align: left; width: 650px;
		&:before { background: $color-primary; bottom: 0; content: ""; height: 2px; left: 0; position: absolute; width: 87px; }
	}
}