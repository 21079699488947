.tri-grid {
  padding: 45px 0 60px;
  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-bottom: 77px;
    padding-bottom: 43px;
    position: relative;
    text-align: center;
    &:before {
      background: $color-primary;
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 50px;
    }
  }
  .row {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -34px;
    .item {
      margin: 0 34px 20px;
      width: calc(33.33% - 68px);
      text-align: center;
      img {
        margin-bottom: 24px;
      }
      h4 {
        font-size: 16px;
        margin-bottom: 10px;
      }
      p {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  &.type-2 {
    background: #f4f4f6;
    padding: 85px 0 118px;
    .row {
      margin: 0 -6px;
      .item {
        margin: 0 6px 20px;
        width: calc(33.33% - 12px);
        &:nth-child(1) h4 {
          background: #1b1464;
        }
        &:nth-child(2) h4 {
          background: #443c9b;
        }
        &:nth-child(3) h4 {
          background: #5e55bf;
          &:after {
            display: none;
          }
        }
        &:last-child h4:after {
          display: none;
        }
        > h4 {
          background: $color-quaternary;
          border-radius: 10px;
          color: $color-initial;
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
          padding: 28px 31px;
          position: relative;
          text-align: center;
          align-items: center;
          display: flex;
          justify-content: center;
          width: 100%;
          &:after {
            border: 8px solid $color-initial;
            border-radius: 100%;
            content: url(../images/material/ic-chevron-circle-red.svg);
            height: 47px;
            position: absolute;
            right: -31px;
            top: 50%;
            z-index: 1;
            @include transform(translateY(-50%));
          }
        }
        > div {
          background: $color-initial;
          border-radius: 10px;
          box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
          font-size: 15px;
          line-height: 24px;
          padding: 40px 30px 80px;
        }
      }
    }
  }
}
.info-2 {
  padding: 43px 0 46px;
  position: relative;
  .background {
    display: block;
    height: auto;
    margin-bottom: 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
  .text {
    position: absolute;
    right: 145px;
    top: 145px;
    width: 457px;
    z-index: 1;
    h3 {
      color: $color-initial;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    p {
      color: $color-initial;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 15px;
    }
    .button {
      font-size: 1.5rem;
      font-weight: 500;
      height: 4rem;
      line-height: 4rem;
      margin-bottom: 0;
    }
  }
}
.product-page {
  padding-top: 97px;
  .product {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    .item {
      background: $color-initial;
      border-radius: 20px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
      margin: 0 10px 30px;
      overflow: hidden;
      position: relative;
      width: calc(100% / 2 - 20px);
      figure {
        text-align: right;
        img {
          display: block;
          height: 100%;
          margin-left: 0;
          margin-right: auto;
          max-width: 336px;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        right: 0;
        padding: 0 30px;
        position: absolute;
        top: 0;
        width: 43%;
        z-index: 2;
        background: #fff;
        * {
          position: relative;
          z-index: 2;
        }
        > img {
          align-self: flex-start;
        }
        img {
          margin-bottom: 20px;
        }
        h4 {
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 11px;
          align-self: flex-start;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 19px;
        }
        a {
          color: $color-secondary;
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
          &:hover {
            color: $color-primary;
          }
        }
        &:before {
          background: url(../images/material/curve-border-white.png) no-repeat
            right center;
          background-size: cover;
          bottom: 0;
          content: "";
          height: 100%;
          left: -163px;
          position: absolute;
          top: 0;
          width: 162px;
          z-index: 1;
        }
        // &:after { background: url(../images/material/ic-heartcurve4.png) no-repeat right center; background-size: cover; bottom: 0; content:  ''; height: 100%; left: 0; position: absolute; right: -40px; top: 0; width: 120%; z-index: 1; }
        // &:after{
        // 	display: none;
        // }
      }
    }
    // .item { background: $color-initial; border-radius: 20px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16); margin: 0 10px 30px; overflow: hidden; position: relative; width: calc(100% / 2 - 20px);
    // 	figure { text-align: right;
    // 		img { display: block; height: 100%; margin-left: auto; margin-right: 0; }
    // 	}
    // 	.text { bottom: 0; left: 0; padding: 71px 55px; position: absolute; top: 0; width: 50%; z-index: 2;
    // 		* { position: relative; z-index: 2; }
    // 		img { margin-bottom: 20px; }
    // 		h4 { font-size: 19px; font-weight: bold; margin-bottom: 11px; }
    // 		p { font-size: 15px; line-height: 24px; margin-bottom: 19px; }
    // 		a { color: $color-secondary; display: inline-block; font-size: 14px; font-weight: bold; line-height: 16px;
    // 			&:hover { color: $color-primary; }
    // 		}
    // 		&:after { bottom: 0; content: url(../images/material/masking-product.png); position: absolute; right: -40px; top: -3px; z-index: 1; }
    // 	}
    // }
  }
}
