.search-box { margin-top: -45px; position: relative; z-index: 13; 
	form { background: $color-initial; border-radius: 20px; box-shadow: 0 3px 6px rgba(0,0,0,.16); display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap;
		input { border: none; margin-bottom: 0; font-size: 23px; height: 90px; padding: 33px 0 32px 40px; width: calc(100% - 50px); }
		button { background: url(../images/material/ic-search.svg) no-repeat left center; border: none; border-radius: 0; height: 90px; margin-bottom: 0; width: 50px; }
	}
}
.help { padding: 103px 0 64px; 
	.wrapper { display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; }
	aside { padding-right: 58px; width: 30%; 
		> span { display: none; }
		.tab { margin: 0; padding: 0; 
			> li { border-bottom: 1px solid #E2E2E2; display: block; margin-bottom: 16px; padding-bottom: 16px; 
				> a { color: $color-quaternary; display: block; font-size: 23px; font-weight: bold; line-height: 34px; }
				&.dropdown.actived > ul { display: block; }
				> ul { display: none; margin: 15px 0 0; 
					> li { margin-bottom: 16px; 
						&:last-child { margin-bottom: 0; }
						> a { color: $color-quaternary; font-size: 16px; 
							&.active { color: $color-primary; }
						}
					}
				}
			}
		}
	}
	.tab-content { width: 70%; 
		> div { display: none; 
			&.active { display: block; }
		}
		.std-content {
			.title { color: $color-primary; }
			.text { border-bottom: 1px solid #E2E2E2; margin-bottom: 28px;
				h5 { color: $color-tertiary; font-size: 18px; line-height: 30px; margin-bottom: 8px; }
				p { color: $color-quaternary; margin-bottom: 28px; }
			}
		}
	}
}
.no-data { padding: 0 0 70px; text-align: center; 
	h4 { display: block; font-size: 20px; font-weight: bold; line-height: 30px; margin: 0 auto; text-align: center; text-transform: uppercase; }
}