@media (max-width: 767px) {
  .info {
    padding: 60px 0 100px;
    > .background {
      object-fit: contain;
      object-position: bottom right;
    }
    .text {
      width: 100%;
      h4 {
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 20px;
      }
      ul li {
        margin-bottom: 20px;
        width: auto;
      }
    }
  }
  .info-2 {
    .background {
      min-height: 133px;
    }
    .text {
      left: 0;
      padding: 0 30px;
      text-align: center;
      top: 50%;
      width: 100%;
      @include transform(translateY(-50%));
      h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      p {
        display: none;
      }
    }
  }
  .tri-grid {
    .title {
      margin-bottom: 46px;
    }
    .row {
      flex-wrap: wrap;
      margin: 0 auto;
      width: 100%;
      .item {
        margin: 0 auto 30px;
        text-align: center;
        width: 100%;
        img {
          margin-bottom: 16px;
        }
        h4 {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &.type-2 .row {
      flex-wrap: wrap;
      margin: 0 auto;
      .item {
        margin: 0 auto 20px;
        width: 100%;
        h4 {
          &:after {
            display: none;
          }
        }
        > div {
          height: auto !important;
          text-align: left;
          padding: 24px;
        }
      }
    }
  }
  .product-page {
    padding: 60px 0;
    .product {
      margin: 0;
      // .item { box-shadow: 0 10px 20px rgba(0,0,0,.16); margin: 0 0 20px; width: 100%;
      // 	.text:after { top: -65px; }
      // }
      .item {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
        margin: 0 0 20px;
        width: 100%;
        figure img {
          height: auto;
          min-height: 190px;
        }
        .text {
          padding: 0 30px;
          width: 45%;
        }
      }
    }
  }
}

@media (max-width: 522px) {
  .product-page {
    .product {
      .item {
        .text {
          padding: 0 24px 0 16px;
        }
      }
    }
  }
}
