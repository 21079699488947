.popup {
  align-items: center;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  overflow-y: auto;
  justify-content: center;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  &.small .inner-popup {
    padding: 0;
    width: 45rem;
  }
  &.medium .inner-popup {
    padding: 0;
    width: 70rem;
  }
  &.polis .inner-popup {
    padding: 0;
    width: 60rem;
  }
  .polis-popup {
    h4 {
      border-bottom: 1px solid #ddd;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      padding: 20px;
    }
    .list {
      padding: 20px;
      .item {
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        font-size: 16px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        min-height: 72px;
        padding: 15px;
        &.add-data {
          color: blue;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          justify-content: center;
        }
        button {
          background: $color-secondary;
          border-color: $color-secondary;
          border-radius: 8px;
          margin-bottom: 0;
          transition: 0.2s all ease;
          &:hover {
            background: $color-primary;
            border-color: $color-primary;
            transition: 0.2s all ease;
          }
        }
      }
    }
    form {
      margin-bottom: 0;
      padding: 20px;
      .row .column.column-30,
      .row .column.column-33,
      .row .column.column-40,
      .row .column.column-50,
      .row .column.column-100 {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 5px;
      }
      textarea {
        border: 1px solid #ccc;
        height: 100px;
        padding: 5px;
      }
      select {
        border: 1px solid #ccc;
        padding-left: 5px;
      }
      input[type="text"],
      input[type="email"] {
        border: 1px solid #ccc;
        padding: 5px;
      }
      .form-group:last-child {
        margin-bottom: 0;
      }
      input[type="submit"],
      button {
        border-radius: 3px;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
  .nilai-pertanggungan {
    h4 {
      border-bottom: 1px solid #ddd;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      padding: 20px 40px;
    }
    form {
      margin-bottom: 0;
      padding: 40px;
      .row .column.column-30,
      .row .column.column-33,
      .row .column.column-40,
      .row .column.column-50,
      .row .column.column-100 {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 5px;
      }
      textarea {
        border-bottom: 1px solid #ccc;
        height: 100px;
        padding: 5px 0;
      }
      select {
        border-bottom: 1px solid #ccc;
      }
      input[type="text"],
      input[type="email"] {
        border-bottom: 1px solid #ccc;
        padding: 5px 0;
      }
      .form-group:last-child {
        margin-bottom: 0;
      }
      input[type="submit"],
      input[type="reset"],
      button {
        background: $color-secondary;
        border-color: $color-secondary;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        margin: 0 5px;
        padding: 0px 10px;
        width: 150px;
        &:hover {
          background: $color-primary;
          border-color: $color-primary;
        }
      }
    }
  }
  .paket-perlindungan {
    > h4 {
      border-bottom: 1px solid #ddd;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0;
      padding: 20px 40px;
    }
    form {
      border: 1px solid #ccc;
      border-radius: 10px;
      margin: 30px;
      select {
        border-bottom: 1px solid #ccc;
        padding: 5px 0;
      }
    }
    .box {
      border-bottom: 1px solid #ccc;
      padding: 30px;
      h4 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }
    .boxx {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -10px;
      padding: 30px 30px 10px;
      label {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .half {
        margin: 0 10px;
        width: calc(50% - 20px);
      }
    }
    .box-soft-orange {
      margin: 20px 30px;
    }
    .opt-list {
      padding: 30px;
      .item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .checkbox-form {
          border: 1px solid #ccc;
          border-radius: 8px;
          cursor: pointer;
          padding: 15px 30px 15px 60px;
          input[type="checkbox"] {
            left: 20px;
            top: 18px;
            &:checked ~ .box-soft-orange {
              display: block;
            }
          }
        }
        .box-soft-orange {
          display: none;
          margin: 0 0 30px;
        }
      }
    }
    .btn-grp {
      padding: 0 30px 30px;
      button {
        margin-right: 10px;
      }
      button,
      input[type="submit"],
      input[type="reset"] {
        background: $color-secondary;
        border-color: $color-secondary;
        &:hover {
          background: $color-primary;
          border-color: $color-primary;
        }
      }
    }
  }
  .close {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 24px;
    z-index: 99;
    img {
      height: auto;
      width: 10px;
    }
  }
  .overlay-popup {
    cursor: pointer;
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .title-popup {
    h4 {
      color: #1b1464;
    }
  }
  .inner-popup {
    height: max-content;
    width: 80rem;
    margin: auto;
    z-index: 2;
    background: $color-initial;
    border-radius: 1rem;
    padding: 3.5rem 6rem;
    position: relative;

    hr.dashed {
      margin-left: -6rem;
      margin-right: -6rem;
    }

    figure {
      img {
        display: block;
        width: 100%;
      }
    }
  }
  &.popup-payment-method {
    .title-dashed {
      margin-bottom: 32px;
      padding-bottom: 28px;
      position: relative;
      font-weight: 500;
      font-size: 21px;
      &::before {
        content: "";
        left: -48px;
        right: -48px;
        border: 1px dashed #e8e8e8;
        bottom: 0;
        position: absolute;
      }
    }
    .wrap-payment {
      padding: 28px 48px;
    }
    .block-box {
      margin: 0 -28px 0 -48px;
      max-height: 600px;
      overflow: auto;
      padding: 0 48px;
      &::-webkit-scrollbar {
        background: #443c9b;
        border-radius: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #443c9b;
        border-radius: 10px;
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #d7d7d7;
        border-radius: 10px;
        width: 10px;
      }
    }
    .block {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .listing-bank {
      &.changes {
        margin-bottom: 30px;
      }
      .change {
        margin-left: auto;
        font-size: 14px;
        color: #433c9b;
        font-weight: 500;
      }
      &.credit {
        figure {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 30px;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .arrow {
          margin-left: 0;
          position: absolute;
          right: 30px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          height: 25px;
        }
        &:has(h6) {
          justify-content: left;
          .arrow {
            margin-left: auto;
          }
        }
      }
      h6 {
        margin-bottom: 0;
        margin-left: 30px;
        font-weight: 400;
      }
      .arrow {
        margin-left: auto;
      }
    }
    .desc-payment {
      margin-bottom: 20px;
      &::first-child {
        margin-top: 40px;
      }
      h4 {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 0;
      }
      h5 {
        margin-bottom: 0;
        font-weight: 700;
      }
      span {
        font-size: 21px;
        font-weight: 500;
      }
      .strikethrough {
        font-size: 14px;
        text-decoration: line-through;
        color: #999999;
      }
      a {
        font-size: 14px;
        color: #433c9b;
        font-weight: 500;
        position: relative;
        padding-right: 20px;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
          width: 12px;
          height: 12px;
          background: url("../images/material/ic-link-blue.svg") no-repeat
            center;
          background-size: contain;
        }
      }
      .column.column-50 {
        margin-bottom: 0;
        &:last-child {
          text-align: right;
        }
      }
    }
    .box-accordion {
      margin-bottom: 35px;
      .button-accordion {
        background-color: transparent;
        padding: 18px 25px;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        align-items: center;
      }
      .content-accordion {
        padding: 0px 25px 30px;
        border: none;
        ul {
          margin: 0;
        }
      }
    }
    .badge-promo {
      font-size: 15px;
      font-weight: 500;
      color: #ffffff;
      padding: 4px 6px;
      background: #c6000b;
      border-radius: 4px;
      margin-left: 11px;
      line-height: 1.3;
    }
    .payment {
      display: block;
      width: 100%;
      height: 60px;
      line-height: 60px;
      background-color: #433c9b;
    }
    form .form-group label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 12px;
      opacity: 0.5;
    }
    .barcode {
      border-top: 1px dashed #e8e8e8;
      padding: 28px 0 40px;
      text-align: center;
      img {
        display: block;
        margin: 0 auto 5px;
      }
      span {
        color: #272727;
        display: block;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .installment {
      border-top: 1px dashed #e8e8e8;
      padding: 28px 0;
      span {
        color: #000;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        opacity: 0.6;
      }
      .table {
        margin: 22px 0;
        table {
          border-collapse: initial;
          thead {
            th {
              background: #f3f3f3;
              border: 1px solid #e8e8e8;
              padding: 12px 16px;
              &:first-child {
                border-top-left-radius: 10px;
              }
              &:last-child {
                border-top-right-radius: 10px;
              }
              &[align="right"] {
                text-align: right;
              }
              &[align="center"] {
                text-align: center;
              }
            }
          }
          tbody {
            tr {
              td {
                border: 1px solid #e8e8e8;
                padding: 12px 16px;
                &[align="right"] {
                  text-align: right;
                }
                &[align="center"] {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  &.popup-blue{
    .inner-popup{
      background-color: #242277;
      color: white;
    }
    .review-text{
      h4{
        color: white;
        margin-bottom: 32px;
      }
      h5{
        margin-bottom: 8px;
      }
      p{
        color: white;
      }
      ol,ul{
        display: flex;
        width: auto;
        flex-direction: column;
        justify-content: center;
        li{
          margin: 0 auto;
          padding-left: 24px;
          &::before{
            color: white;
          }
        }
      }
    }
    .button{
      border: 1px solid transparent;
      line-height: 39px;
      &:hover{
        background-color: transparent;
        border: 1px solid red;
      }
    }
  }
}
