@media (max-width: 767px) {
  .content-with-banner {
    margin-top: -3rem;
    .wrapper {
      padding-top: 43px;
    }
    .side-line {
      padding-left: 20px;
      &:before {
        height: 40px;
      }
    }
    h4 {
      font-size: 1.5rem;
      line-height: 2.6rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }
  .box-accordion:hover .button-accordion,
  .box-accordion.active .button-accordion {
    padding: 19px 20px 16px;
  }
  .box-accordion {
    &.blue {
      .button-accordion {
        padding: 19px 50px 19px 20px;
        &:before {
          right: 15px;
          top: 18px;
        }
      }
      .content-accordion {
        .box-accordion {
          .button-accordion:before {
            right: 15px;
            top: 13px;
          }
          .content-accordion table {
            margin-bottom: 0;
            tr {
              &:nth-child(odd) {
                background: transparent;
              }
              td {
                display: block;
                &:nth-child(odd) {
                  background: #f5f5f5;
                }
              }
            }
          }
        }
      }
    }
    .button-accordion {
      &:before {
        bottom: unset;
        height: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .row {
        flex-direction: row;
        flex-wrap: wrap;
        .column.column-45 {
          flex: 0 0 100%;
          margin-bottom: 22px;
          max-width: 100%;
          h5 {
            width: 85%;
          }
        }
        .column.column-25,
        .column.column-30 {
          flex: 0 0 auto;
          max-width: max-content;
        }
        .column.column-25 {
          padding-right: 33px;
        }
      }
      h4 {
        font-size: 16px;
        line-height: normal;
      }
    }
    .content-accordion {
      padding: 29px 22px;
      .button {
        width: 100%;
      }
    }
  }
  .terms-and-condition {
    padding-top: 33px;
    .column-no-margin {
      .row {
        flex-direction: row;
        flex-wrap: wrap;
        .column.column-50 {
          flex: 0 0 100%;
          margin-bottom: 30px;
          max-width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .row {
          margin: 0 -10px;
          width: calc(100% + 20px);
          .column {
            display: flex;
            &.column-30 {
              margin: 0 10px;
              flex: 0 0 calc(30% - 9px);
              max-width: calc(30% - 9px);
            }
          }
        }
      }
    }
    .button,
    input[type="submit"] {
      width: 100%;
    }
  }
}
