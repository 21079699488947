@media (max-width: 767px) {
  .popup .inner-popup {
    padding: 3rem 2.4rem;
    hr.dashed {
      margin: 3rem -2.3rem;
    }
    .overflow-popup {
      button,
      .button {
        width: 100%;
      }
      .review-text {
        > img {
          height: auto;
          width: 150px;
        }
        h4 {
          font-size: 1.7rem;
          line-height: 2.8rem;
          margin-bottom: 3rem;
          padding: 0 30px;
        }
        p {
          font-size: 1.4rem;
          line-height: 2.4rem;
          margin-bottom: 4rem;
          width: 100%;
        }
      }
    }
  }
  .popup.small .inner-popup {
    width: 90%;
  }
  .popup.medium .inner-popup {
    width: 90%;
  }
  .popup .paket-perlindungan {
    > h4 {
      padding: 20px 60px 20px 30px;
      text-align: left;
    }
    .boxx .half {
      margin: 0 0 30px;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .box-soft-orange {
      margin: 20px 20px;
    }
    .opt-list {
      padding: 30px 20px;
    }
    .btn-grp {
      padding: 0 20px 30px;
      button,
      input[type="submit"],
      input[type="reset"] {
        width: 100%;
      }
    }
  }
  .popup .nilai-pertanggungan {
    > h4 {
      font-size: 18px;
      padding: 20px 60px 20px 30px;
    }
    .close {
      top: 21px;
    }
    form {
      padding: 40px 30px;
      input[type="submit"],
      input[type="reset"],
      button {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
  .popup {
    &.popup-payment-method {
      .title-dashed::before {
        left: -14px;
        right: -14px;
      }
      .wrap-payment {
        padding: 25px 14px;
      }
      .block-box {
        margin: 0;
        padding: 0 20px 0 0;
      }
      .listing-bank {
        .list {
          padding: 10px 20px;
          .arrow {
            right: 10px;
          }
        }
      }
      .desc-payment {
        flex-flow: row wrap;
      }
      .payment {
        height: 45px;
        line-height: 45px;
      }
      .badge-promo {
        font-size: 12px;
      }
      h6 {
        display: flex;
        align-items: center;
        gap: 0 10px;
        flex-wrap: wrap;
        .badge-promo {
          margin-left: 0;
          margin-top: 5px;
          display: inline-block;
        }
      }
    }
  }
}
