.header-login{
	background: $color-initial;
	border-bottom: .1rem solid #E5E5E5;
	width: 65%;
	box-sizing: border-box;
	z-index: 100;
	padding: 1.6rem 3rem 1.6rem 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	.right-header{
		display: flex;
		justify-content: space-between;
		align-items: center;

		p, a{
			margin-bottom: 0rem;
		}
		p{
			margin-right: 2rem;
			margin-top: .5rem;
		}
	}
}

.section-login{
	display: flex; flex-wrap: wrap;
	&.type2 {
		min-height: 720px;
		.logo { left: 40px; position: absolute; top: 40px; 
			> img { height: 50px; }
		}
		.inner-login { flex: 1 0 60%; max-width: 60%; padding: 0; position: relative; }
		.foooter { align-items: center; bottom: 0; display: block; height: 110px; left: 0; overflow: hidden; position: absolute; padding: 30px; right: 0; 
			&:after { 
				background: url(../images/material/ic-heartcurve.png) no-repeat bottom center;
		    background-size: 100%;
		    bottom: -140px;
		    content: '';
		    height: 320px;
		    position: absolute;
		    right: -20px;
		    transform: rotate(90deg) scaleX(-1);
		    width: 190px;
		    z-index: -1;
		 	}
		 	&.register { position: relative; }
		 	a { display: inline-block; }
		}
		.center-login {
			h4{ font-size: 3.2rem; margin-bottom: 5px;}
			a { font-weight: bold; }
			form {
				.checkbox-form { padding-left: 3rem;
					span { font-weight: bold; }
				}
				input[type="submit"], .button { width: 100%; }
			}
		}
		.slick-list,.slick-track,.slick-slide { height: 100%; }
		.figure-slider { 
			flex: 1 0 40%; max-width: 40%; height: 100vh;
			min-height: 720px;
			figure { height: 100%; overflow-y: hidden; position: relative; 
				img{height: 100%;width: 100%;object-fit: cover;}
				figcaption { font-size: unset; font-weight: unset; text-align: center; width: 50%; margin: 0 auto;
					color: $color-initial;
					position: absolute; bottom: 7rem; left: 6rem;
					h3 { font-size: 3.5rem; }
					p { font-size: 16px; line-height: 30px; opacity: .7; }
				}
			}
			.slick-dots {
				position: absolute;
		    bottom: 30px;
		    z-index: 9;
		    display: block;
		    margin: 0 auto;
		    left: 0;
		    right: 0;
		    text-align: center;
		    li { display: inline-block; margin-bottom: 0; margin: 0 .5rem; padding: 0; width: auto;
					&.slick-active {
						button { opacity: 1; }
					}
					button { background: #fff; border: transparent; border-radius: 50%; display: block; height: 10px; line-height: unset; margin-bottom: 0; min-width: auto; opacity: .5; padding: 0; text-indent: -99999px; width: 10px; }
					&:only-child { display: none; }
				}
			}
		}
	}
	&.register { height: 100vh; min-height: 100vh; 
		.inner-login { height: 100%; padding-top: 130px; padding-bottom: 0; }
		.figure-slider { height: 100vh; }
		.foooter.register { margin-top: 100px; width: 100%; }
	}
	&.login {
		.center-login {
    	min-height: calc(100% - 130px - 220px);
	    margin: 100px auto 38px;
		}
	}
	> figure{
		flex: 1 0 35%;
		max-width: 35%;
		position: relative;

		img{height: 100%;width: 100%;object-fit: cover;}
		figcaption{
			font-size: 3.5rem;
			font-weight: 700;
			color: $color-initial;
			position:absolute; bottom: 7rem; right: 6rem;
		}
	}
	.inner-login{
		flex: 1 0 65%;
		max-width: 65%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 11rem 0 0;
	}
	.center-login{
		width: 50rem;
		margin: auto;
	}

	form{
		.form-group{
			.column{
				margin-bottom: 0rem;
			}
			.checkbox-form.light input[type=checkbox] { top: 0; }
			select { padding-left: 0;}
		}
	}

	.text-soft{
		color: #A1A1A1;
		a{
			color: #1B1464;
			&:hover{text-decoration:underline;}
		}
	}
}

.divider-text{
	position: relative;
	text-align: center;
	margin-bottom: 3rem;

	&:before{
		content:"";
		background: #E5E5E5;
		height: .1rem;
		position: absolute; top: 50%; right: 0; left: 0;
	}
	span{
		display: inline-block;
		background: $color-initial;
		padding: 0 1.4rem;
		position: relative;
		z-index: 2;
	}
}