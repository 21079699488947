@media (max-width: 991px) {
	.side-line { margin: 0 auto; padding-left: 35px; width: 85%; }
	.content-with-banner {
		h4 { font-size: 1.7rem; line-height: 3rem; }
	}
	.box-accordion .button-accordion {
		span { font-size: 1rem; line-height: 1.4rem; }
		h5 { font-size: 1.4rem; line-height: 2.4rem; }
	}
	.box-accordion.blue { margin: 0 0rem 3rem; }
}