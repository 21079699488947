.underconstruction { align-items: center; display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; height: 100vh; min-height: 540px; position: relative; width: 100%;
	figure { width: 60%; 
		img { display: block; height: 100vh; object-fit: cover; object-position: right center; width: 100%; }
	}
	.text {
		img { margin-bottom: 46px; }
		.title { font-size: 40px; font-weight: bold; line-height: 60px; margin-bottom: 31px; max-width: 100%; padding-bottom: 19px; position: relative; text-align: left;
			&:before { background: $color-primary; bottom: 0; content: ""; height: 2px; left: 0; position: absolute; width: 87px; }
		}
		p { font-size: 16px; line-height: 30px; }
	}
}
.page-404 { text-align: center; padding-top: 46px;
	.img { display: block; margin: 0 auto; width: 342px; }
	.title { font-size: 40px; font-weight: bold; line-height: 60px; margin-bottom: 31px; max-width: 100%; padding-bottom: 19px; position: relative; text-align: center;
		&:before { background: $color-primary; bottom: 0; content: ""; height: 2px; left: 0; margin: 0 auto; position: absolute; right: 0; width: 87px; }
	}
	p { margin: 0 auto 30px; text-align: center; width: 700px; }
}
.terms-and-condition { padding-top: 60px; 
	.wrapper { padding: 0 200px; }
}
.search-result { padding-top: 60px; 
	.wrapper { padding: 0 200px; }
	.note { display: block; color: $color-quinary; font-size: 16px; line-height: 28px; }
	.search-result-form { border-radius: 20px; box-shadow: 0 3px 6px rgba(0,0,0,.16); margin-bottom: 46px;
		form { display: flex; display: -webkit-flex; flex-direction: row; flex-wrap: nowrap; 
			input[type="text"] { border: none; margin-bottom: 0; font-size: 23px; height: 90px; padding: 33px 40px; width: calc(100% - 76px); }
			button { background: url(../images/material/ic-search.svg) no-repeat center; border: none; border-radius: 0 0 20px 20px; height: 90px; margin-bottom: 0; }
		}
	}
	.search-list { margin: 46px 0; 
		.item { border-top: 1px solid #E2E2E2; display: block; padding: 28px 0 37px;
			h4 { color: $color-tertiary; font-size: 18px; font-weight: normal; line-height: 30px; margin-bottom: 10px; }
			p { color: $color-quinary; font-size: 16px; line-height: 30px; margin-bottom: 0; }
		}
	}
}
.ty-page { margin: 100px auto 0; width: 70%;
	h3 { font-weight: bold; }
}