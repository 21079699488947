@media (max-width: 1279px){
	.section-login {
		> figure figcaption { bottom: 123px; font-size: 23px; line-height: 34px; left: 6rem; right: 6rem; }
		.inner-login { align-items: center; display: flex; padding: 110px 0 80px; }
		.center-login { width: 85%; 
			h4 { font-size: 19px; line-height: 30px; margin-bottom: 6px; }
			.text-soft { font-size: 16px; line-height: 30px; margin-bottom: 40px; }
		}
	}
	.section-login.type2 {
		&.register {
			.inner-login { align-items: center; padding: 130px 0 0; }
			// .foooter.register { position: absolute; width: 55%; }
		}
		.logo { width: 120px;}
		.inner-login { flex: 1 0 55%; max-width: 55%; }
		.foooter { width: 100%; 
			&:after {
				height: 290px;
			}
			br { display: none; }
		}
		.figure-slider { flex: 1 0 45%; max-width: 45%;
			figure figcaption { left: 4rem; width: auto; right: 4rem;
				h3 { font-size: 24px; line-height: normal; }
				p { line-height: normal;}
			}
		}
	}
	.section-login.login .center-login,
	.section-login.login .foooter.register { margin-top: 100px; }
}
@media (max-width: 991px){
	.section-login { align-content: flex-start; flex-direction: row; flex-wrap: wrap;
		> figure { flex: 0 0 100%; height: 300px; margin-top: 80px; max-width: 100%; 
			img { object-fit: cover; object-position: top center; }
			figcaption { bottom: 41px; }
		}
		.inner-login { flex: 0 0 100%; padding: 67px 0; max-width: 100%; 
			&.less-pad { padding: 40px 0; }
		}
		.center-login { width: 70%; 
			h4 { font-size: 17px; line-height: 30px; }
			.text-soft { margin-bottom: 10px; 
				&:last-child { font-size: 15px; line-height: 24px; margin-bottom: 0; }
			}
			.form-group.row:last-child { margin-bottom: 10px; }
		}
	}
	.section-login.type2 {
		 min-height: auto;
		.logo { background: #fff; border-bottom: .1rem solid #E5E5E5; left: 0; padding: 1.2rem 4rem 1.2rem 4rem; position: fixed; top: 0; width: 100%; z-index: 99;
			> img { height: 48px; }
		}
		.inner-login { flex: 0 0 100%; max-width: 100%; min-height: calc(100vh - 360px); order: 2; position: initial; padding: 70px 0 0;
			&:after { display: none;}
		}
		.figure-slider { flex: 0 0 100%; max-width: 100%; order: 1; height: auto; min-height: auto;
			figure { height: 300px; margin-top: 80px; 
				figcaption { bottom: 3rem; width: 60%; }
			}
			.slick-dots { bottom: 10px; }
		}
		.foooter { margin-top: 40px; order: 3; position: relative; width: 100%; 
			br {
				display: block;
			}
		}
		&.register { height: auto;
			.inner-login { padding: 67px 0 0; }
			.foooter.register { position: relative; width: 100%; }
		}
	}
	.section-login.login .inner-login { min-height: auto; }
	.section-login.login .center-login { margin: 0 auto 37px; }
	.section-login.login .foooter.register { margin-top: 0; }
}