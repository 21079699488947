@media (max-width: 767px) {
	.section-account { padding: 40px 0 48px; }
	.title-icon { margin-bottom: 20px;
		img { height: 20px; margin-right: 5px; top: 4px; width: auto; }
		h2 { font-size: 1.7rem; line-height: 30px; }
	}
	.box-photo-upload { 
		flex-wrap: wrap;
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		padding: 1.5rem 1.5rem;
		> img { align-self: flex-start; position: unset;} 
		h6 { font-size: 1.3rem; }
		.text-photo-upload { margin-bottom: 0; width: calc(100% - 60px - 20px - 75px); }
		.btn-delete { font-size: 1.3rem; width: 75px; 
			&:before { margin-right: 5px; }
		}
	}
	.section-account .box-aside aside {
		.account-aside { padding: 1.5rem 2.4rem; 
			&:after { right: 30px; }
			h5 { font-size: 14px; line-height: 24px; }
		}
		.nav-aside > li { padding: 1.6rem 2.3rem 1.6rem 5.8rem; }
	}
	.nav-aside > li > a > img { height: 2.2rem; width: 2.2rem; }
	.box-polis-perjalanan {
		.body-polis-perjalanan {
			.column-36 { border: none; border-bottom: 1px solid #E5E5E5; flex: 0 0 100%; max-width: 100%; padding: 19px 25px; }
			.column-64 { flex: 0 0 100%; max-width: 100%; padding: 19px 25px; }
			table {
				tr { display: block; margin-bottom: 20px;
					&:last-child { margin-bottom: 0; }
				}
				td, th { display: block; padding: 0; }
			}
			h5 { font-size: 16px; line-height: 30px; margin-bottom: 5px; }
		}
		.foot-polis-perjalanan { padding: 19px 25px; 
			.column-36, .column-64 { flex: 0 0 100%; margin-bottom: 21px; max-width: 100%; padding: 0; }
			.column-right { margin-bottom: 0; text-align: left; }
		}
	}
	.tab-polis {
		.btn-tab { display: block; overflow: auto; padding: 39px 20px 0;
			ul { width: max-content; 
				li { margin-right: 3rem; width: auto; }
			}
		}
		.list-content-tab { padding: 17px 20px; 
			.button { border-radius: 10px; font-size: 13px; padding: 0 2rem; }
			h4 { font-size: 16px; line-height: 30px; }
			p { font-size: 14px; line-height: 20px; }
		}
	}
	.box-list-info .list-info { padding: 22px 17px 22px 17px; 
		> img { height: 26px; left: 24px; top: 24px; width: auto; }
		.text-list-info { margin-bottom: 14px; padding-left: 40px; }
		.button { display: block; position: relative; right: unset; top: unset; }
	}
	.box-aside article .title-profile { padding: 1.8rem 2.3rem 1.8rem 5.5rem; 
		> img { left: 2rem; }
    button, .button { margin: 15px 0 0; font-size: 12px; padding: 0 30px; }
	}
	.box-polis-list .polis-list { flex-wrap: wrap; margin-bottom: 20px; padding: 14px 20px; 
		.text-rounded-list { flex: 0 0 100%; margin-bottom: 18px; max-width: 100%; 
			h5 { font-size: 14px; line-height: 30px; }
			span { font-size: 13px; line-height: 20px; }
		}
	}
	.box-dash-rounded .form-group.row.no-margin .column.column-50:first-child { margin-bottom: 10px; }
	.box-polis-perjalanan {
		.title-dash { font-size: 1.8rem; padding: 20px; }
		.titles { padding: 0 20px; }
		.berlaku-polis form { padding: 0 20px; 
			select { width: 100%; }
		}
	}
	.box-polis-perjalanan .btn-action { padding: 0 20px;
		button { width: 100%; }
	}
	.box-aside article .content-profile hr.dashed { margin-left: -2rem; margin-right: -2rem; }
}