@media (max-width: 1279px) {
  .product-page {
    .product {
      .item {
        figure img {
          max-width: 216px;
          height: 250px;
          object-fit: cover;
        }
        .text {
          padding: 0 30px 0 16px;
          width: 50%;
          img {
            margin-bottom: 15px;
          }
          h4 {
            font-size: 17px;
            margin-bottom: 12px;
          }
          p {
            line-height: 22px;
          }
          a {
            font-size: 13px;
          }
        }
        // figure img { height: 250px; }
        // .text { padding: 36px 0px 36px 30px; width: 58%;
        // 	&:after { top: -30px; }
        // 	img { margin-bottom: 15px; }
        // 	h4 { font-size: 17px; margin-bottom: 12px; }
        // 	p { line-height: 22px; }
        // 	a { font-size: 13px; margin-bottom: 24px; }
        // }
      }
    }
  }
  .tri-grid .title {
    font-size: 19px;
    line-height: 30px;
    margin-bottom: 40px;
    padding-bottom: 29px;
  }
  .info .text {
    width: 50%;
    h3 {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 50px;
      padding-bottom: 20px;
    }
    ul.type-arrow li {
      font-size: 15px;
      line-height: 24px;
    }
  }
  .info-2 {
    padding: 60px 0;
    .text {
      right: 0;
      top: calc(50% + 35px);
      padding: 0 40px;
      width: 50%;
      @include transform(translateY(calc(-50% - 15px)));
      h3 {
        font-size: 23px;
        line-height: 30px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 991px) {
  .product-page {
    .product {
      .item {
        figure img {
          max-width: 230px;
          height: 207px;
        }
        .text {
          width: 45%;
          padding: 0 21px;
          img {
            margin-bottom: 10px;
          }
          h4 {
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 20px;
          }
          p {
            display: none;
          }
          &::before {
            left: -162px;
          }
        }
      }
    }
  }
  .info {
    padding: 70px 0 90px;
    > .background {
      object-position: center;
    }
    .text {
      width: 65%;
      h3 {
        font-size: 17px;
        margin-bottom: 30px;
        padding-bottom: 21px;
      }
      h4 {
        margin-bottom: 50px;
      }
      p {
        display: none;
      }
      a.link-with-icon:after {
        top: -1px;
      }
    }
  }
  .info-2 {
    padding: 40px 0 60px;
    .text {
      padding-left: 0;
      h3 {
        font-size: 17px;
        line-height: 30px;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
        max-height: 54px;
        overflow: hidden;
      }
    }
  }
  .tri-grid {
    .title {
      font-size: 17px;
      margin-bottom: 61px;
      padding-bottom: 20px;
    }
    .row {
      margin: 0 -12px;
      .item {
        margin: 0 12px 20px;
        width: calc(33.33% - 24px);
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    &.type-2 {
      padding: 49px 0 67px;
      .row .item {
        > h4 {
          font-size: 14px;
          padding: 31px 20px;
        }
        > div {
          font-size: 14px;
          padding: 24px 32px 30px;
        }
      }
    }
  }
}
