@media (max-width: 767px) {
	.banner-slider .item {
		figure img { height: 480px; min-height: 480px; max-height: unset; }
		.wrapper { top: calc(50% - 50px); }
		.item-text { width: 80%; 
			h2 { font-size: 19px; line-height: 28px; margin-bottom: 15px; }
			p { font-size: 14px; line-height: 26px; margin-bottom: 17px; }
		}
	}
	.product-highlight .highlight { margin-top: -95px; margin-bottom: 40px;
		figure { display: none; }
		.text { border-radius: 20px; padding: 20px 16px; text-align: center; width: 100%; 
			.assurance { margin-bottom: 15px;
				> span { height: auto; padding: 0 35px; 
					&:after { transform-origin: center; @include transform(translateY(-50%) scale(.7)); }
				}
				.select-assurance { width: 100%; }
			}
			.button-blue { position: relative; top: unset; right: unset; width: 100%; @include transform(translateY(0)); }
		}
	}
	.product-highlight .about-product { flex-wrap: wrap; margin-top: 40px;
    .item { margin: 0 0 30px; text-align: center; width: 100%; 
      > img {margin-right: 0;}
			.text { margin: 0 auto; width: 80%; }
		}
	}
	.our-product .product-slider {
		.item {
			figure img { max-width: 55%; height: auto; min-height: 190px; }
			.text { padding: 0 30px; width: 45%;}
		}
		.slick-arrow {
			&.arrow-next { right: 0px; }
			&.arrow-prev { left: -5px; }
		}
	}
	.promotion .promotion-list { flex-wrap: wrap; margin: 0; padding-bottom: 30px;
		.highlight { margin: 0; margin-bottom: 26px; min-height: 280px; padding: 42px 62px; width: 100%; }
		.item-list { position: relative; width: 100%; 
			.item { margin: 0;
				h4 { font-size: 14px; line-height: 24px; padding-right: 50px; }
			}
			.slick-arrow { position: absolute; top: 40%; z-index: 99; @include transform(translateY(-50%)); 
				&.arrow-next { right: -10px; }
				&.arrow-prev { left: -10px; }
			}
		}
	}
	.blog { padding: 46px 0 10px; 
		.blog-list .item { flex-wrap: wrap;
			&:first-child {
				figure { margin-bottom: 0; }
				.text { padding: 20px 25px 20px 20px; 
					.date { top: 11px; }
					h4 { font-size: 17px; line-height: 24px; }
				}
			}
			figure { margin-bottom: 17px; width: 100%; 
				.tag { bottom: 31px; font-size: 13px; left: 20px; padding: 5px 15px; }
			}
			.text { padding-left: 0; width: 100%; 
				h4 { font-size: 14px; line-height: 24px; margin-bottom: 14px; }
			}
		}
	}
	.contact-me {
		> img { object-fit: contain; }
		.text { top: 90px;
			p { font-size: 14px; line-height: 20px; }
		}
		form { margin-top: -65px; padding: 30px 30px 15px; 
			.input-field { margin: 0 0 20px; width: 100%; 
				&:last-child { margin-bottom: 0;
					button { margin-top: 0; }
				}
			}
		}
	}
	.testimonial { padding: 35px 0 30px;
		.testimonial-slider { padding: 32px 28px 32px 17px; 
			.item p { margin-bottom: 20px; }
		}
	}
}


@media (max-width: 522px) {
	.our-product .product-slider {
		.item {
			.text { padding: 0 24px 0 16px;}
		}
	}
}